import { Injectable } from '@angular/core';
import { ICheckboxProps } from '@profis-engineering/pe-ui-common/entities/main-menu/checkbox-props';
import { IControlProps } from '@profis-engineering/pe-ui-common/entities/main-menu/control-props';
import { IMainMenuControl, IMenu } from '@profis-engineering/pe-ui-common/entities/main-menu/menu';
import {
    BaseControl, CustomControl, Menu
} from '@profis-engineering/pe-ui-common/entities/main-menu/navigation';
import { ITextBoxProps } from '@profis-engineering/pe-ui-common/entities/main-menu/textbox-props';
import { IModalOpened } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import {
    ILocalizationExtension
} from '@profis-engineering/pe-ui-common/services/extensions.common';
import {
    IMenu2dServiceExtensions, Menu2dServiceInjected
} from '@profis-engineering/pe-ui-common/services/menu-2d.common';

import { DesignC2C } from '../../shared/entities/design-c2c';
import {
    AdvancedPointsTableType, IAdvancedPointsTableProps
} from '../../shared/entities/main-menu/main-menu-controls';
import {
    UIProperty
} from '../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Entities';
import { Controls2dEditor, IMenu2dContext } from '../../shared/services/menu-2d.service.base';
import { AdvancedPointsTable } from '../components/main-menu/points-table/AdvancedPointsTable';
import { CodeListService } from './code-list.service';
import {
    createAdvancedPointsTable2d, isPointsTableHidden, onChangeAddPointTable,
    onChangePointsTableCell, onCommitAddPointTable, onCommitPointTable, onDeletePointTable,
    pirTableVisualProperties, PointsTableServices, updatePointsTable
} from './helpers/custom-controls-helper/advanced-points-table';
import { LocalizationService } from './localization.service';
import { MenuService } from './menu.service';
import { ModalService } from './modal.service';
import { NumberService } from './number.service';
import { StaticMenu2dService } from './static-menu-2d.service';
import { UnitService } from './unit.service';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root'
})
export class Menu2dService extends Menu2dServiceInjected implements IMenu2dServiceExtensions<IMenu2dContext> {
    constructor(
        private menuService: MenuService,
        private modalService: ModalService,
        private staticMenu2dService: StaticMenu2dService,
        private unitService: UnitService,
        private localizationService: LocalizationService,
        private userService: UserService,
        private numberService: NumberService,
        private codeListService: CodeListService
    ) {
        super();
    }

    private get design() {
        return this.userService.design;
    }

    public initialize() {
        this.setMenuExtensions({} as IMenu2dServiceExtensions<IMenu2dContext>);
        this.menuExtensions.localizationExtension = {
            getTranslation: this.localizationService.getString.bind(this.localizationService)
        } as ILocalizationExtension;

        this.menuExtensions.getMenuStructure = this.getMenuStructure.bind(this);
        this.menuExtensions.getMenuCommands = this.getMenuCommands.bind(this);
        this.menuExtensions.getMenuModals = this.getMenuModals.bind(this);

        this.menuExtensions.getMenuControls = this.getMenuControls.bind(this);
        this.menuExtensions.updateMenuControlsProps = this.updateMenuControlsProps.bind(this);
        this.menuExtensions.onMenuStateChanged = this.onMenuStateChanged.bind(this);
        this.menuExtensions.onMenuAllowedValuesChanged = this.onMenuAllowedValuesChanged.bind(this);

        this.menuExtensions.getSelectedTab = this.getSelectedTab.bind(this);

        this.menuExtensions.createCheckbox = this.createCheckbox.bind(this);
        this.menuExtensions.createTextBox = this.createTextBox.bind(this);
        this.menuExtensions.createToggleButtonGroup = this.createToggleButtonGroup.bind(this);
        this.menuExtensions.createCustomControl = this.createCustomControl.bind(this);
    }

    // Data
    public getMenuStructure(design: DesignC2C): Menu<BaseControl<string>, string> | undefined {
        return this.staticMenu2dService.initializeMenu(design);
    }

    public getMenuCommands(
        _design: DesignC2C,
        commands: Record<string, (navigationControl: BaseControl<string>) => void>
    ): Record<string, (navigationControl: BaseControl<string>) => void> {
        return {
            ['OpenApprovalC2C']: commands['OpenApprovalC2C'],
            ['OpenDesignSettingsPopup']: () => commands['OpenDesignSettingsPopup'],
            ['OpenTransverseReinforcementDescriptionPopup']: () => this.modalService.openInfoDialogC2C('OpenTransverseReinforcementDefineInfoPopup'),
            ['OpenTransverseReinforcementUnfavorableTolerancePopup']: () => this.modalService.openInfoDialogC2C('OpenTransverseReinforcementUnfavorableTolerancePopup', 'lg'),
            ['OpenRebarPositionPopup']: () => this.modalService.openInfoDialogC2C('OpenRebarPositionPopup'),
            ['OpenSurfaceTreatmentPopup']: () => this.modalService.openInfoDialogC2C('OpenSurfaceTreatmentPopup'),
            ['OpenSpliceClassesPopup']: () => this.modalService.openInfoDialogC2C('OpenSpliceClassesPopup'),
            ['OpenIncludeReinforcementCompressionPopup']: () => this.modalService.openInfoDialogC2C('OpenIncludeReinforcementCompressionPopup', 'lg'),
            ['OpenDefineOtherRebarParameters']: commands['OpenDefineOtherRebarParameters'],
            ['OpenProductTransverseReinforcementPopup']: () => this.modalService.openInfoDialogC2C('OpenProductTransverseReinforcementPopup'),
        };
    }

    public getMenuModals(): Record<number, (input?: object | undefined) => IModalOpened> {
        return {};
    }

    // Controls
    public getMenuControls(): { [id: string]: IControlProps } | undefined {
        return undefined;
    }

    public updateMenuControlsProps(controlPropsList: { [id: string]: IControlProps }, design: DesignC2C, menu: IMenu, getControlName: (name: string) => string, getControlByName: (menu: IMenu, name: string) => IControlProps): void {
        const c1 = controlPropsList[getControlName(Controls2dEditor.ExistingReinforcement)] ?? {} as IControlProps;
        c1.hidden = design.properties.get(UIProperty.ExistingStructure_C2C_Reinforcement_RebarPoints).hidden;

        const c2 = controlPropsList[getControlName(Controls2dEditor.PostInstalledRebar)] ?? {} as IControlProps;
        c2.hidden = design.properties.get(UIProperty.Product_C2C_PostInstalledRebar_RebarPoints).hidden;

        const c3 = controlPropsList[getControlName(Controls2dEditor.PostInstalledRebar)] ?? {} as any;
        c3.isEditable = !design.properties.get(UIProperty.Product_C2C_PostInstalledRebar_RebarPoints).disabled;

        const c4 = (controlPropsList[getControlName(Controls2dEditor.GridSpacing)] ?? {}) as ITextBoxProps;
        c4.value = this.unitService.formatUnitAsDefault((getControlByName(menu, getControlName(Controls2dEditor.GridSpacing)) as ITextBoxProps).value ?? '', UnitGroup.Length);

        const c5 = (controlPropsList[getControlName(Controls2dEditor.ShowGrid)] ?? {}) as ICheckboxProps;
        c5.checked = (getControlByName(menu, getControlName(Controls2dEditor.ShowGrid)) as ICheckboxProps).checked;
    }

    public onMenuStateChanged(context: IMenu2dContext) {
        this.updatePointsTable(context, AdvancedPointsTableType.PostInstallRebar);
        this.updatePointsTable(context, AdvancedPointsTableType.ExistingReinforcement);
    }

    public onMenuAllowedValuesChanged(design: DesignC2C, propertyIds: number[], setState: (fn?: ((prevMenu: IMenu) => IMenu) | undefined) => IMenu) {
        setState(menu => {
            const controlPropsList: { [id: string]: IControlProps } = {};

            for (const id in this.menuService.onAllowedValuesChangedUpdaters) {
                if (this.menuService.onAllowedValuesChangedUpdaters[id] != null) {
                    controlPropsList[id] = this.menuService.onAllowedValuesChangedUpdaters[id](design, propertyIds, menu);
                }
            }

            return this.menuService.updateAllMainMenuControls(menu, controlPropsList) as IMenu;
        });
    }

    // Tab
    public getSelectedTab(): string | undefined {
        return 'tab-layout';
    }


    // Helpers
    public createCheckbox(): void {
        /* Nothing to do. */
    }

    public createTextBox(): void {
        /* Nothing to do. */
    }

    public createToggleButtonGroup(): void {
        /* Nothing to do. */
    }

    public createCustomControl(context: IMenu2dContext, navControl: CustomControl<IControlProps, string>, _control: IMainMenuControl, customControlProps: IControlProps) {
        if (customControlProps.type == AdvancedPointsTable) {
            const pointsTableProps = (customControlProps as IAdvancedPointsTableProps);
            const services = {
                codeListService: this.codeListService,
                localizationService: this.localizationService,
                numberService: this.numberService,
                unitService: this.unitService
            } as PointsTableServices;

            const type = createAdvancedPointsTable2d(this.design, services, context.glModelComponent, navControl.Name, pointsTableProps);

            if (type != null) {
                pointsTableProps.tableType = type;
                pointsTableProps.canInsert = pirTableVisualProperties(this.design).canInsert;
                pointsTableProps.hidden = isPointsTableHidden(this.design, pointsTableProps.items, type);
                pointsTableProps.valueChanged = onChangePointsTableCell.bind(this, context, type);
                pointsTableProps.onCommit = onCommitPointTable.bind(this, context, this.design, services, type);
                pointsTableProps.onDelete = onDeletePointTable.bind(this, context, this.design, services, type);
                pointsTableProps.addInputChanged = onChangeAddPointTable.bind(this, context, type);
                pointsTableProps.addInputCommit = onCommitAddPointTable.bind(this, context, this.design, services, type);
            }
        }
    }

    public updatePointsTable(context: IMenu2dContext, pointsTableProps: AdvancedPointsTableType){
        updatePointsTable(context, this.design, this.localizationService, this.numberService, pointsTableProps);
    }
}

export type MainMenuCommandC2C =
    'OpenApprovalC2C' |
    'OpenAlphaSustained' |
    'OpenContactSurfaceConditionPopup' |
    'OpenCastInSplicedReinforcementPopup' |
    'OpenEpoxyCoatedReinforcementPopup' |
    'OpenLocationFactorPopup' |
    'OpenDetailedDefinitionPopup' |
    'OpenOptimizedValuesPopup' |
    'OpenRebarEpoxyCoatedPopup' |
    'OpenSpliceToExistingReinforcementPopup' |
    'OpenOverstrengthPopup' |
    'OpenExcessReinforcementPopup' |
    'OpenPhiTensionPopup' |
    'OpenIsYieldDesignPopup' |
    'OpenAnchorTheoryDataPopup' |
    'OpenPermanentNetCompressionPopup' |
    'OpenZoneAnalysis' |
    'OpenRatioBInfoBox' |
    'OpenDesignSettingsPopup' |
    'OpenTransverseReinforcementDescriptionPopup' |
    'OpenShearDesignOptionDeltaFsPopup' |
    'OpenShearDesignOptionInterfacePopup' |
    'OpenConcreteDensityPopup' |
    'OpenTensileStrengthPopup' |
    'OpenExtensionAtSupportPopup' |
    'OpenContinuousInXPopup' |
    'OpenCrackedConcretePopup' |
    'OpenDesignForYieldPopup' |
    'OpenMaxAreaReinforcementPopup' |
    'OpenSurfaceRoughnessPopup' |
    'OpenMinAreaReinforcementItemsC2C' |
    'OpenCompressionConfinementPopup' |
    'OpenDefineOtherRebarParameters' |
    'OpenDefinitionOfMinMaxReinforcement' |
    'OpenAdditionalOffsetDistancePopup' |
    'OpenRecommendFireFastener' |
    'OpenFireResistanceDurationPopup' |
    'OpenDrillingAidPopup' |
    'OpenTransverseReinforcementUnfavorableTolerancePopup' |
    'OpenIncludeReinforcementCompressionPopup' |
    'OpenDesignMethodGroupsSelectionPopup' |
    'OpenUseFullCrossSectionPopup' |
    'OpenBondBreakoutPopup' |
    'OpenRebarPositionPopup' |
    'OpenSurfaceTreatmentPopup' |
    'OpenSpliceClassesPopup' |
    'OpenSurfaceReinforcementInfoPopup' |
    'OpenCrackWidthPopup' |
    'OpenUseHiltiDesign' |
    'OpenDistanceCipPir' |
    'OpenAnchorTheoryPopup' |
    'OpenRebarSpacingPopup' |
    'OpenStrutAndTiePopup' |
    'OpenDesignMethodGroupsOverlayPopup' |
    'OpenHiltiMethodRebarDesignPopup' |
    'OpenAnchoringBarYieldPopup' |
    'OpenAnchoringExternalLoadsPopup' |
    'OpenAllowResearchBasedPopup' |
    'OpenSpliceClassesAlternativePopup' |
    'OpenConnectorPositionOverlayPopup' |
    'OpenTransverseReinforcementDefineInfoPopup' |
    'OpenConcreteMaterialSettingsInfoPopup' |
    'OpenSustainability' |
    'OpenNetCompressionPopup' |
    'OpenScopeCheckInfoPopup' |
    'OpenLapSpliceLengthPopup' |
    'OpenProductTransverseReinforcementPopup';

export enum Command {
    OpenApproval = 1003,
    OpenAlphaSustained = 1004,
    OpenContactSurfaceConditionPopup = 1005,
    OpenCastInSplicedReinforcementPopup = 1006,
    OpenEpoxyCoatedReinforcementPopup = 1007,
    OpenLocationFactorPopup = 1008,
    OpenDetailedDefinitionPopup = 1009,
    OpenOptimizedValuesPopup = 1010,
    OpenRebarEpoxyCoatedPopup = 1011,
    OpenSpliceToExistingReinforcementPopup = 1012,
    OpenOverstrengthPopup = 1013,
    OpenExcessReinforcementPopup = 1014,
    OpenPhiTensionPopup = 1015,
    OpenIsYieldDesignPopup = 1016,
    OpenAnchorTheoryDataPopup = 1017,
    OpenPermanentNetCompressionPopup = 1018,
    OpenZoneAnalysis = 1022,
    OpenRatioBInfoBox = 1023,
    OpenDesignSettingsPopup = 1024,
    OpenTransverseReinforcementDescriptionPopup = 1025,
    OpenShearDesignOptionDeltaFsPopup = 1026,
    OpenShearDesignOptionInterfacePopup = 1027,
    OpenConcreteDensityPopup = 1028,
    OpenTensileStrengthPopup = 1029,
    OpenExtensionAtSupportPopup = 1030,
    OpenContinuousInXPopup = 1031,
    OpenCrackedConcretePopup = 1032,
    OpenDesignForYieldPopup = 1033,
    OpenMaxAreaReinforcementPopup = 1034,
    OpenSurfaceRoughnessPopup = 1035,
    OpenMinAreaReinforcementItemsC2C = 1036,
    OpenCompressionConfinementPopup = 1037,
    OpenDefineOtherRebarParameters = 1038,
    OpenDefinitionOfMinMaxReinforcement = 1039,
    OpenAdditionalOffsetDistancePopup = 1040,
    OpenRecommendFireFastener = 1041,
    OpenFireResistanceDurationPopup = 1042,
    OpenDrillingAidPopup = 1043,
    OpenTransverseReinforcementUnfavorableTolerancePopup = 1044,
    OpenIncludeReinforcementCompressionPopup = 1045,
    OpenDesignMethodGroupsSelectionPopup = 1046,
    OpenUseFullCrossSectionPopup = 1047,
    OpenBondBreakoutPopup = 1048,
    OpenRebarPositionPopup = 1049,
    OpenSurfaceTreatmentPopup = 1050,
    OpenSpliceClassesPopup = 1051,
    OpenSurfaceReinforcementInfoPopup = 1052,
    OpenCrackWidthPopup = 1053,
    OpenUseHiltiDesign = 1054,
    OpenDistanceCipPir = 1055,
    OpenAnchorTheoryPopup = 1056,
    OpenRebarSpacingPopup = 1057,
    OpenStrutAndTiePopup = 1058,
    OpenDesignMethodGroupsOverlayPopup = 1059,
    OpenHiltiMethodRebarDesignPopup = 1060,
    OpenAnchoringBarYieldPopup = 1061,
    OpenAnchoringExternalLoadsPopup = 1062,
    OpenAllowResearchBasedPopup = 1063,
    OpenSpliceClassesAlternativePopup = 1064,
    OpenConnectorPositionOverlayPopup = 1065,
    OpenTransverseReinforcementDefineInfoPopup = 1066,
    OpenConcreteMaterialSettingsInfoPopup = 1067,
    OpenNetCompressionPopup = 1068,
    OpenScopeCheckInfoPopup = 1069,
    OpenLapSpliceLengthPopup = 1070,
    OpenProductTransverseReinforcementPopup = 1071
}

const commandDictionary: { [id: number]: MainMenuCommandC2C } = {
    [Command.OpenApproval]: 'OpenApprovalC2C',
    [Command.OpenAlphaSustained]: 'OpenAlphaSustained',
    [Command.OpenContactSurfaceConditionPopup]: 'OpenContactSurfaceConditionPopup',
    [Command.OpenCastInSplicedReinforcementPopup]: 'OpenCastInSplicedReinforcementPopup',
    [Command.OpenEpoxyCoatedReinforcementPopup]: 'OpenEpoxyCoatedReinforcementPopup',
    [Command.OpenLocationFactorPopup]: 'OpenLocationFactorPopup',
    [Command.OpenDetailedDefinitionPopup]: 'OpenDetailedDefinitionPopup',
    [Command.OpenOptimizedValuesPopup]: 'OpenOptimizedValuesPopup',
    [Command.OpenRebarEpoxyCoatedPopup]: 'OpenRebarEpoxyCoatedPopup',
    [Command.OpenSpliceToExistingReinforcementPopup]: 'OpenSpliceToExistingReinforcementPopup',
    [Command.OpenOverstrengthPopup]: 'OpenOverstrengthPopup',
    [Command.OpenExcessReinforcementPopup]: 'OpenExcessReinforcementPopup',
    [Command.OpenPhiTensionPopup]: 'OpenPhiTensionPopup',
    [Command.OpenIsYieldDesignPopup]: 'OpenIsYieldDesignPopup',
    [Command.OpenAnchorTheoryDataPopup]: 'OpenAnchorTheoryDataPopup',
    [Command.OpenPermanentNetCompressionPopup]: 'OpenPermanentNetCompressionPopup',
    [Command.OpenZoneAnalysis]: 'OpenZoneAnalysis',
    [Command.OpenRatioBInfoBox]: 'OpenRatioBInfoBox',
    [Command.OpenDesignSettingsPopup]: 'OpenDesignSettingsPopup',
    [Command.OpenTransverseReinforcementDescriptionPopup]: 'OpenTransverseReinforcementDescriptionPopup',
    [Command.OpenShearDesignOptionDeltaFsPopup]: 'OpenShearDesignOptionDeltaFsPopup',
    [Command.OpenShearDesignOptionInterfacePopup]: 'OpenShearDesignOptionInterfacePopup',
    [Command.OpenConcreteDensityPopup]: 'OpenConcreteDensityPopup',
    [Command.OpenTensileStrengthPopup]: 'OpenTensileStrengthPopup',
    [Command.OpenExtensionAtSupportPopup]: 'OpenExtensionAtSupportPopup',
    [Command.OpenContinuousInXPopup]: 'OpenContinuousInXPopup',
    [Command.OpenCrackedConcretePopup]: 'OpenCrackedConcretePopup',
    [Command.OpenDesignForYieldPopup]: 'OpenDesignForYieldPopup',
    [Command.OpenMaxAreaReinforcementPopup]: 'OpenMaxAreaReinforcementPopup',
    [Command.OpenSurfaceRoughnessPopup]: 'OpenSurfaceRoughnessPopup',
    [Command.OpenMinAreaReinforcementItemsC2C]: 'OpenMinAreaReinforcementItemsC2C',
    [Command.OpenCompressionConfinementPopup]: 'OpenCompressionConfinementPopup',
    [Command.OpenDefineOtherRebarParameters]: 'OpenDefineOtherRebarParameters',
    [Command.OpenDefinitionOfMinMaxReinforcement]: 'OpenDefinitionOfMinMaxReinforcement',
    [Command.OpenAdditionalOffsetDistancePopup]: 'OpenAdditionalOffsetDistancePopup',
    [Command.OpenRecommendFireFastener]: 'OpenRecommendFireFastener',
    [Command.OpenFireResistanceDurationPopup]: 'OpenFireResistanceDurationPopup',
    [Command.OpenDrillingAidPopup]: 'OpenDrillingAidPopup',
    [Command.OpenTransverseReinforcementUnfavorableTolerancePopup]: 'OpenTransverseReinforcementUnfavorableTolerancePopup',
    [Command.OpenIncludeReinforcementCompressionPopup]: 'OpenIncludeReinforcementCompressionPopup',
    [Command.OpenDesignMethodGroupsSelectionPopup]: 'OpenDesignMethodGroupsSelectionPopup',
    [Command.OpenUseFullCrossSectionPopup]: 'OpenUseFullCrossSectionPopup',
    [Command.OpenBondBreakoutPopup]: 'OpenBondBreakoutPopup',
    [Command.OpenRebarPositionPopup]: 'OpenRebarPositionPopup',
    [Command.OpenSurfaceTreatmentPopup]: 'OpenSurfaceTreatmentPopup',
    [Command.OpenSpliceClassesPopup]: 'OpenSpliceClassesPopup',
    [Command.OpenSurfaceReinforcementInfoPopup]: 'OpenSurfaceReinforcementInfoPopup',
    [Command.OpenCrackWidthPopup]: 'OpenCrackWidthPopup',
    [Command.OpenUseHiltiDesign]: 'OpenUseHiltiDesign',
    [Command.OpenDistanceCipPir]: 'OpenDistanceCipPir',
    [Command.OpenAnchorTheoryPopup]: 'OpenAnchorTheoryPopup',
    [Command.OpenRebarSpacingPopup]: 'OpenRebarSpacingPopup',
    [Command.OpenStrutAndTiePopup]: 'OpenStrutAndTiePopup',
    [Command.OpenDesignMethodGroupsOverlayPopup]: 'OpenDesignMethodGroupsOverlayPopup',
    [Command.OpenHiltiMethodRebarDesignPopup]: 'OpenHiltiMethodRebarDesignPopup',
    [Command.OpenAnchoringBarYieldPopup]: 'OpenAnchoringBarYieldPopup',
    [Command.OpenAnchoringExternalLoadsPopup]: 'OpenAnchoringExternalLoadsPopup',
    [Command.OpenAllowResearchBasedPopup]: 'OpenAllowResearchBasedPopup',
    [Command.OpenSpliceClassesAlternativePopup]: 'OpenSpliceClassesAlternativePopup',
    [Command.OpenConnectorPositionOverlayPopup]: 'OpenConnectorPositionOverlayPopup',
    [Command.OpenTransverseReinforcementDefineInfoPopup]: 'OpenTransverseReinforcementDefineInfoPopup',
    [Command.OpenConcreteMaterialSettingsInfoPopup]: 'OpenConcreteMaterialSettingsInfoPopup',
    [Command.OpenNetCompressionPopup]: 'OpenNetCompressionPopup',
    [Command.OpenScopeCheckInfoPopup]: 'OpenScopeCheckInfoPopup',
    [Command.OpenLapSpliceLengthPopup]: 'OpenLapSpliceLengthPopup',
    [Command.OpenProductTransverseReinforcementPopup]: 'OpenProductTransverseReinforcementPopup',
};

export function commandFromService(command: Command): MainMenuCommandC2C
{
    return commandDictionary[command];
}

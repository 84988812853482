import { Injectable } from '@angular/core';
import { LabelRole } from '@profis-engineering/pe-ui-common/entities/main-menu/control-props';
import { IMenu } from '@profis-engineering/pe-ui-common/entities/main-menu/menu';
import { ButtonSize, ButtonType, CustomControl, NavigationTabWidth, TooltipType } from '@profis-engineering/pe-ui-common/entities/main-menu/navigation';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { MainMenuCommandC2C } from '../../shared/entities/command';
import { DesignC2C } from '../../shared/entities/design-c2c';
import { IAdvancedPointsTableProps, ISelectRebarProps } from '../../shared/entities/main-menu/main-menu-controls';
import { ConnectionType } from '../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Enums';
import { MenuC2C, RegionC2C, TabC2C } from '../../shared/services/static-menu.service.base';
import { FeaturesVisibilityService } from './features-visibility.service';
import { initializeSelectRebar } from './helpers/custom-controls-helper/select-rebar';
import { checkConnectionType, getCommandConditional, getPopupTooltipConditional, isACICSA, isASBased, isPirAS, isPirEU } from './helpers/static-menu-helper';
import { LocalizationService } from './localization.service';
import { ModalService } from './modal.service';
import { IModalOpened } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { initializeAdvancedPointsTable } from '../components/main-menu/points-table/AdvancedPointsTable';
import { getSpriteAsIconStyle } from '../sprites';
import { UnitService } from './unit.service';

@Injectable({
    providedIn: 'root'
})
export class StaticMenu2dService {
    constructor(
        private featureVisibilityService: FeaturesVisibilityService,
        private unitService: UnitService,
        private localizationService: LocalizationService,
        private modalService: ModalService
    ) { }

    public initializeMenu(design: DesignC2C) {
        const menu2d = this.createC2CMenu2d(design, this.unitService);

        if (isPirEU(design) || isPirAS(design)) {
            const tabs = this.createC2CMenu2dPir(design, this.localizationService, this.modalService);
            tabs.forEach(element => {
                menu2d.Tabs.push(element);
            });
        }

        this.fillControlProperties(menu2d);

        return menu2d;
    }

    private createC2CMenu2d(design: DesignC2C, unitService: UnitService): MenuC2C {
        return {
            Id: 2,
            Favorites: false,
            Tabs: [
                this.createC2CMenu2d_Edit2d(),
                this.createC2CMenu2d_Coordinates2d(design, unitService)
            ],
            Footer: {
                Controls: []
            }
        };
    }

    private createC2CMenu2dPir(design: DesignC2C, localizationService: LocalizationService, modalService: ModalService): TabC2C[] {
        return [
            this.createC2CMenu2dPir_ExistingReinforcement(design),
            this.createC2CMenu2dPir_Product(design, localizationService, modalService)
        ];
    }

    private createC2CMenu2dPir_Product(design: DesignC2C, localizationService: LocalizationService, modalService: ModalService): TabC2C {
        return {
            DisplayKey: isPirEU(design) || isPirAS(design) ? 'Agito.Hilti.C2C.Navigation.TabPIR' : 'Agito.Hilti.C2C.Navigation.TabProduct',
            Id: 6,
            Name: isPirEU(design) || isPirAS(design) ? 'pir-tab' : 'product-tab',
            Image: 'rebar-icon',
            IconImage: getSpriteAsIconStyle('sprite-rebar-icon'),
            Width: NavigationTabWidth.Normal,
            TabRegions: [
                this.createC2CMenu2dPir_Product_Rebar(design, localizationService, modalService),
                this.createC2CMenu2dPir_Product_CalculationMode(),
                this.createC2CMenu2dPir_Product_Reinforcement(),
                this.creacteC2CMenu2d_Product_TransverseReinforcement()
            ]
        };
    }

    private createC2CMenu2dPir_Product_Rebar(design: DesignC2C, localizationService: LocalizationService, modalService: ModalService): RegionC2C {
        return {
            DisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.Rebar',
            Id: 20,
            Name: 'rebar-section',
            Controls: [
                {
                    ControlType: 'CustomControl',
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    initialize: (controlProps: ISelectRebarProps, _modals: Record<number, (input?: object) => IModalOpened>, setState: (fn?: (prevMenu: IMenu) => IMenu) => IMenu) => initializeSelectRebar(controlProps, localizationService, modalService, design, 10031, setState),
                    Name: 'Rebar.SelectedFamilyRebar',
                    UIPropertyId: 10031,
                } as unknown as CustomControl<ISelectRebarProps, MainMenuCommandC2C>,
                {
                    CodelistName: 'FastenerType',
                    ControlType: 'Dropdown',
                    Name: 'Rebar.TypeDropdown',
                    TitleDisplayKey: 'Agito.Hilti.C2C.Connector.Type.Title',
                    UIPropertyId: 10440,
                    TooltipDisplayKey: isACICSA(design) ? 'Agito.Hilti.C2C.Connector.Type.Tooltip.HNA' : 'Agito.Hilti.C2C.Connector.Type.Tooltip',
                    TooltipTitleDisplayKey: 'Agito.Hilti.C2C.Connector.Type.Tooltip'
                },
                {
                    ControlType: 'Button',
                    ButtonSize: ButtonSize.Full,
                    ButtonType: ButtonType.Default,
                    Name: 'Rebar.ViewETAApproval',
                    UIPropertyId: 10032,
                    Command: 'OpenApprovalC2C',
                    DisplayKey: 'Agito.Hilti.C2C.ViewApproval'
                },
                {
                    ControlType: 'Button',
                    ButtonSize: ButtonSize.Full,
                    ButtonType: ButtonType.Default,
                    Name: 'Rebar.ViewUKTAApproval',
                    UIPropertyId: 10757,
                    Command: 'OpenApprovalC2C',
                    DisplayKey: 'Agito.Hilti.C2C.ViewApproval.UKTA',
                    Hidden: !this.featureVisibilityService.isFeatureEnabled('C2C_UKTA')
                },
                {
                    ControlType: 'Label',
                    Name: 'Rebar.ItemNumber',
                    TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.Connector.RebarItem',
                    UIPropertyId: 10503
                },
                {
                    ControlType: 'Label',
                    Name: 'Rebar.AdhesiveItemNumber',
                    TitleDisplayKey: 'Agito.Hilti.Profis3.InfoSection.AnchorSection.ArticleNumberChemical',
                    UIPropertyId: 10034
                },
                {
                    ControlType: 'TextBox',
                    UnitGroup: UnitGroup.Stress,
                    Name: 'Rebar.ReinforcementYieldStrength',
                    UIPropertyId: 10592,
                    TitleDisplayKey: isASBased(design) ? 'Agito.Hilti.C2C.Reinforcement.Fsy' : 'Agito.Hilti.C2C.Reinforcement.ReinforcementYieldStrengthFyk'
                },
                {
                    ControlType: 'Checkbox',
                    TitleDisplayKey: 'Agito.Hilti.C2C.SelectRebar.FilterGroups.DesignBasis',
                    DisplayKey: 'Agito.Hilti.C2C.CodeList.ProductFilterEntity.UKTAAllowed',
                    Name: 'Rebar.ViewUKTAApprovalCheckBox',
                    UIPropertyId: 10759,
                    Hidden: !this.featureVisibilityService.isFeatureEnabled('C2C_UKTA')
                }
            ]
        };
    }

    private createC2CMenu2dPir_Product_CalculationMode(): RegionC2C {
        return {
            Id: 93,
            Name: 'calculation-mode',
            DisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.CalculationMode',
            Controls: [
                {
                    ControlType: 'Dropdown',
                    Name: 'CalculationMode.Dropdown',
                    CodelistName: 'CalculationMode',
                    UIPropertyId: 10570,
                    TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.CalculationMode.RebarPosition',
                    TooltipType: TooltipType.Popup,
                    Command: 'OpenRebarPositionPopup'
                },
                {
                    ControlType: 'TextBox',
                    UnitGroup: UnitGroup.Length,
                    Name: 'CalculationMode.MinConcreteCoverOptimization',
                    TitleDisplayKey: 'Agito.Hilti.C2C.Product.MinConcreteCoverOptimization',
                    UIPropertyId: 10689
                }
            ]
        };
    }

    private createC2CMenu2dPir_Product_Reinforcement(): RegionC2C {
        return {
            Id: 94,
            Name: 'reinforcement',
            DisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.Reinforcement',
            Controls: [
                {
                    ControlType: 'Dropdown',
                    Name: 'Reinforcement.OptionDropdown',
                    CodelistName: 'LongitudinalReinforcementOption',
                    UIPropertyId: 10571,
                },
                {
                    ControlType: 'Label',
                    Name: '2d-mode-disclaimer-label',
                    UIPropertyId: 10703,
                    Role: LabelRole.Regular
                },
                {
                    ControlType: 'TabGroup',
                    Name: 'reinforcement-layers-tab',
                    Tabs: [
                        {
                            DisplayKey: 'Agito.Hilti.C2C.Reinforcement.TopLayer',
                            Name: 'reinforcement-layers-tab-top-layer',
                            Tag: 'top-layer'
                        },
                        {
                            DisplayKey: 'Agito.Hilti.C2C.Reinforcement.BottomLayer',
                            Name: 'reinforcement-layers-tab-bottom-layer',
                            Tag: 'bottom-layer'
                        }
                    ]
                },
                {
                    ControlType: 'Checkbox',
                    DisplayKey: 'Agito.Hilti.C2C.Reinforcement.TopLayer',
                    Name: 'Reinforcement.ShowTopLayer',
                    UIPropertyId: 10627,
                    ParentControlName: 'reinforcement-layers-tab',
                    ParentControlTag: 'top-layer'
                },
                {
                    ControlType: 'Dropdown',
                    Name: 'Reinforcement.TopLayersCount',
                    UIPropertyId: 10685,
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.NumberOfLayers',
                    ParentControlName: 'reinforcement-layers-tab',
                    ParentControlTag: 'top-layer',
                    CodelistName: 'NumberOfLayers'
                },
                {
                    CodelistName: 'BondCondition',
                    ControlType: 'RadioButtonGroup',
                    Name: 'Reinforcement.BondConditionTopLayersRadioButtonGroup',
                    Size: 100,
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.LongitudinalReinforcement.BondCondition',
                    UIPropertyId: 10578,
                    ParentControlName: 'reinforcement-layers-tab',
                    ParentControlTag: 'top-layer'
                },
                {
                    ControlType: 'Label',
                    Name: 'Reinforcement.LayerLabelTopLayer1',
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.LayerOne',
                    UIPropertyId: 10629,
                    ParentControlName: 'reinforcement-layers-tab',
                    ParentControlTag: 'top-layer',
                    Role: LabelRole.SubHeading
                },
                {
                    CodelistName: 'FastenerSize',
                    ControlType: 'Dropdown',
                    Name: 'Reinforcement.DiameterToplayer1Dropdown',
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.LongitudinalReinforcement.Diameter',
                    UIPropertyId: 10572,
                    UnitGroup: UnitGroup.Length,
                    ParentControlName: 'reinforcement-layers-tab',
                    ParentControlTag: 'top-layer'
                },
                {
                    CodelistName: 'SpacingBarsOption',
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.BarsArrangement',
                    ControlType: 'RadioButtonGroup',
                    Name: 'Reinforcement.SpacingBarsOptionsTopLayer1RadioButtonGroup',
                    UIPropertyId: 10573,
                    ParentControlName: 'reinforcement-layers-tab',
                    ParentControlTag: 'top-layer',
                    Size: 50,
                },
                {
                    ControlType: 'TextBox',
                    Name: 'Reinforcement.NumberOfBarsTopLayer1',
                    UIPropertyId: 10619,
                    ParentControlName: 'reinforcement-layers-tab',
                    ParentControlTag: 'top-layer',
                    Size: 50,
                },
                {
                    ControlType: 'TextBox',
                    Name: 'Reinforcement.SpacingTopLayer1',
                    UIPropertyId: 10620,
                    UnitGroup: UnitGroup.Length,
                    ParentControlName: 'reinforcement-layers-tab',
                    ParentControlTag: 'top-layer',
                    Size: 50,
                },
                {
                    ControlType: 'TextBox',
                    Name: 'Reinforcement.TopCoverTopLayer1',
                    UIPropertyId: 10574,
                    UnitGroup: UnitGroup.Length,
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.TopCover',
                    ParentControlName: 'reinforcement-layers-tab',
                    ParentControlTag: 'top-layer',
                    Size: 50,
                },
                {
                    ControlType: 'TextBox',
                    Name: 'Reinforcement.SideCoverTopLayer1',
                    UIPropertyId: 10691,
                    UnitGroup: UnitGroup.Length,
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.SideCover',
                    ParentControlName: 'reinforcement-layers-tab',
                    ParentControlTag: 'top-layer',
                    Size: 50,
                },
                {
                    ControlType: 'TextBox',
                    Name: 'Reinforcement.MinSideCoverTopLayer1',
                    UIPropertyId: 10724,
                    UnitGroup: UnitGroup.Length,
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.MinSideCover',
                    ParentControlName: 'reinforcement-layers-tab',
                    ParentControlTag: 'top-layer',
                    Size: 50,
                },
                {
                    ControlType: 'Label',
                    Name: 'Reinforcement.LayerLabelTopLayer2',
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.LayerTwo',
                    UIPropertyId: 10630,
                    ParentControlName: 'reinforcement-layers-tab',
                    ParentControlTag: 'top-layer',
                    Role: LabelRole.SubHeading
                },
                {
                    CodelistName: 'FastenerSize',
                    ControlType: 'Dropdown',
                    Name: 'Reinforcement.DiameterTopLayer2Dropdown',
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.LongitudinalReinforcement.Diameter',
                    UIPropertyId: 10575,
                    UnitGroup: UnitGroup.Length,
                    ParentControlName: 'reinforcement-layers-tab',
                    ParentControlTag: 'top-layer'
                },
                {
                    CodelistName: 'SpacingBarsOption',
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.BarsArrangement',
                    ControlType: 'RadioButtonGroup',
                    Name: 'Reinforcement.SpacingBarsOptionsTopLayer2RadioButtonGroup',
                    UIPropertyId: 10576,
                    ParentControlName: 'reinforcement-layers-tab',
                    ParentControlTag: 'top-layer',
                    Size: 50,

                },
                {
                    ControlType: 'TextBox',
                    Name: 'Reinforcement.NumberOfBarsTopLayer2',
                    UIPropertyId: 10621,
                    ParentControlName: 'reinforcement-layers-tab',
                    ParentControlTag: 'top-layer',
                    Size: 50
                },
                {
                    ControlType: 'TextBox',
                    Name: 'Reinforcement.SpacingTopLayer2',
                    UIPropertyId: 10622,
                    UnitGroup: UnitGroup.Length,
                    ParentControlName: 'reinforcement-layers-tab',
                    ParentControlTag: 'top-layer',
                    Size: 50
                },
                {
                    ControlType: 'TextBox',
                    Name: 'Reinforcement.TopCoverTopLayer2',
                    UIPropertyId: 10577,
                    UnitGroup: UnitGroup.Length,
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.TopCover',
                    ParentControlName: 'reinforcement-layers-tab',
                    ParentControlTag: 'top-layer',
                    Size: 50,
                },
                {
                    ControlType: 'TextBox',
                    Name: 'Reinforcement.SideCoverTopLayer2',
                    UIPropertyId: 10692,
                    UnitGroup: UnitGroup.Length,
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.SideCover',
                    ParentControlName: 'reinforcement-layers-tab',
                    ParentControlTag: 'top-layer',
                    Size: 50,
                },
                {
                    ControlType: 'TextBox',
                    Name: 'Reinforcement.MinSideCoverTopLayer2',
                    UIPropertyId: 10725,
                    UnitGroup: UnitGroup.Length,
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.MinSideCover',
                    ParentControlName: 'reinforcement-layers-tab',
                    ParentControlTag: 'top-layer',
                    Size: 50,
                },
                {
                    ControlType: 'Checkbox',
                    DisplayKey: 'Agito.Hilti.C2C.Reinforcement.BottomLayer',
                    Name: 'Reinforcement.ShowBottomLayer',
                    UIPropertyId: 10628,
                    ParentControlName: 'reinforcement-layers-tab',
                    ParentControlTag: 'bottom-layer'
                },
                {
                    ControlType: 'Dropdown',
                    Name: 'Reinforcement.BottomLayersCount',
                    UIPropertyId: 10686,
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.NumberOfLayers',
                    ParentControlName: 'reinforcement-layers-tab',
                    ParentControlTag: 'bottom-layer',
                    CodelistName: 'NumberOfLayers'
                },
                {
                    CodelistName: 'BondCondition',
                    ControlType: 'RadioButtonGroup',
                    Name: 'Reinforcement.BondConditionBottomLayersDropdown',
                    Size: 100,
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.LongitudinalReinforcement.BondCondition',
                    UIPropertyId: 10585,
                    ParentControlName: 'reinforcement-layers-tab',
                    ParentControlTag: 'bottom-layer'
                },
                {
                    ControlType: 'Label',
                    Name: 'Reinforcement.LayerLabelBottomLayer1',
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.LayerOne',
                    UIPropertyId: 10631,
                    ParentControlName: 'reinforcement-layers-tab',
                    ParentControlTag: 'bottom-layer',
                    Role: LabelRole.SubHeading
                },
                {
                    CodelistName: 'FastenerSize',
                    ControlType: 'Dropdown',
                    Name: 'Reinforcement.DiameterBottomLayer1Dropdown',
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.LongitudinalReinforcement.Diameter',
                    UIPropertyId: 10579,
                    UnitGroup: UnitGroup.Length,
                    ParentControlName: 'reinforcement-layers-tab',
                    ParentControlTag: 'bottom-layer'
                },
                {
                    CodelistName: 'SpacingBarsOption',
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.BarsArrangement',
                    ControlType: 'RadioButtonGroup',
                    Name: 'Reinforcement.SpacingBarsOptionsBottomLayer1RadioButtonGroup',
                    UIPropertyId: 10580,
                    ParentControlName: 'reinforcement-layers-tab',
                    ParentControlTag: 'bottom-layer',
                    Size: 50,
                },
                {
                    ControlType: 'TextBox',
                    Name: 'Reinforcement.NumberOfBarsBottomLayer1',
                    UIPropertyId: 10623,
                    ParentControlName: 'reinforcement-layers-tab',
                    ParentControlTag: 'bottom-layer',
                    Size: 50,
                },
                {
                    ControlType: 'TextBox',
                    Name: 'Reinforcement.SpacingBottomLayer1',
                    UIPropertyId: 10624,
                    UnitGroup: UnitGroup.Length,
                    ParentControlName: 'reinforcement-layers-tab',
                    ParentControlTag: 'bottom-layer',
                    Size: 50,
                },
                {
                    ControlType: 'TextBox',
                    Name: 'Reinforcement.TopCoverBottomLayer1',
                    UIPropertyId: 10581,
                    UnitGroup: UnitGroup.Length,
                    TitleDisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.BottomCover',
                    ParentControlName: 'reinforcement-layers-tab',
                    ParentControlTag: 'bottom-layer',
                    Size: 50,
                },
                {
                    ControlType: 'TextBox',
                    Name: 'Reinforcement.SideCoverBottomLayer1',
                    UIPropertyId: 10693,
                    UnitGroup: UnitGroup.Length,
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.SideCover',
                    ParentControlName: 'reinforcement-layers-tab',
                    ParentControlTag: 'bottom-layer',
                    Size: 50,
                },
                {
                    ControlType: 'TextBox',
                    Name: 'Reinforcement.MinSideCoverBottomLayer1',
                    UIPropertyId: 10726,
                    UnitGroup: UnitGroup.Length,
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.MinSideCover',
                    ParentControlName: 'reinforcement-layers-tab',
                    ParentControlTag: 'bottom-layer',
                    Size: 50,
                },
                {
                    ControlType: 'Label',
                    Name: 'Reinforcement.LayerLabelBottomLayer2',
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.LayerTwo',
                    UIPropertyId: 10632,
                    ParentControlName: 'reinforcement-layers-tab',
                    ParentControlTag: 'bottom-layer',
                    Role: LabelRole.SubHeading
                },
                {
                    CodelistName: 'FastenerSize',
                    ControlType: 'Dropdown',
                    Name: 'Reinforcement.DiameterBottomLayer2Dropdown',
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.LongitudinalReinforcement.Diameter',
                    UIPropertyId: 10582,
                    UnitGroup: UnitGroup.Length,
                    ParentControlName: 'reinforcement-layers-tab',
                    ParentControlTag: 'bottom-layer'
                },
                {
                    CodelistName: 'SpacingBarsOption',
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.BarsArrangement',
                    ControlType: 'RadioButtonGroup',
                    Name: 'Reinforcement.SpacingBarsOptionsBottomLayer2RadioButtonGroup',
                    UIPropertyId: 10583,
                    ParentControlName: 'reinforcement-layers-tab',
                    ParentControlTag: 'bottom-layer',
                    Size: 50,
                },
                {
                    ControlType: 'TextBox',
                    Name: 'Reinforcement.NumberOfBarsBottomLayer2',
                    UIPropertyId: 10625,
                    ParentControlName: 'reinforcement-layers-tab',
                    ParentControlTag: 'bottom-layer',
                    Size: 50,
                },
                {
                    ControlType: 'TextBox',
                    Name: 'Reinforcement.SpacingBottomLayer2',
                    UIPropertyId: 10626,
                    UnitGroup: UnitGroup.Length,
                    ParentControlName: 'reinforcement-layers-tab',
                    ParentControlTag: 'bottom-layer',
                    Size: 50,
                },
                {
                    ControlType: 'TextBox',
                    Name: 'Reinforcement.TopCoverBottomLayer2',
                    UIPropertyId: 10584,
                    UnitGroup: UnitGroup.Length,
                    TitleDisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.BottomCover',
                    ParentControlName: 'reinforcement-layers-tab',
                    ParentControlTag: 'bottom-layer',
                    Size: 50,
                },
                {
                    ControlType: 'TextBox',
                    Name: 'Reinforcement.SideCoverBottomLayer2',
                    UIPropertyId: 10694,
                    UnitGroup: UnitGroup.Length,
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.SideCover',
                    ParentControlName: 'reinforcement-layers-tab',
                    ParentControlTag: 'bottom-layer',
                    Size: 50,
                },
                {
                    ControlType: 'TextBox',
                    Name: 'Reinforcement.MinSideCoverBottomLayer2',
                    UIPropertyId: 10727,
                    UnitGroup: UnitGroup.Length,
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.MinSideCover',
                    ParentControlName: 'reinforcement-layers-tab',
                    ParentControlTag: 'bottom-layer',
                    Size: 50,
                },
                {
                    CodelistName: 'BondCondition',
                    ControlType: 'RadioButtonGroup',
                    Name: 'Reinforcement.BondConditionArrangementsRadioButtonGroup',
                    Size: 100,
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.LongitudinalReinforcement.BondCondition',
                    UIPropertyId: 10590,
                },
                {
                    CodelistName: 'FastenerSize',
                    ControlType: 'Dropdown',
                    Name: 'Reinforcement.DiameterArrangementsDropdown',
                    Size: 50,
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.LongitudinalReinforcement.Diameter',
                    UIPropertyId: 10586,
                    UnitGroup: UnitGroup.Length
                },
                {
                    ControlType: 'TextBox',
                    Name: 'Reinforcement.NumberOfBarsArrangements',
                    Size: 50,
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.LongitudinalReinforcement.NumberOfBars',
                    UIPropertyId: 10656,
                },
                {
                    ControlType: 'TextBox',
                    Name: 'Reinforcement.NumberOfBarsXArrangements',
                    Size: 50,
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.LongitudinalReinforcement.NumberOfBarsX',
                    UIPropertyId: 10587,
                },
                {
                    ControlType: 'TextBox',
                    Name: 'Reinforcement.NumberOfBarsYArrangements',
                    Size: 50,
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.LongitudinalReinforcement.NumberOfBarsY',
                    UIPropertyId: 10588,
                },
                {
                    ControlType: 'TextBox',
                    Name: 'Reinforcement.CoverArrangements',
                    Size: 50,
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.LongitudinalReinforcement.Cover',
                    UIPropertyId: 10589,
                    UnitGroup: UnitGroup.Length,
                },
                {
                    ControlType: 'TextBox',
                    UnitGroup: UnitGroup.Length,
                    UIPropertyId: 10756,
                    Name: 'pir-rebar-spacing',
                    Size: 50,
                    TitleDisplayKey: 'Agito.Hilti.C2C.Product.PirRebarSpacing',
                    TooltipDisplayKey: 'Agito.Hilti.C2C.Product.PirRebarSpacing.Tooltip',
                    TooltipTitleDisplayKey: 'Agito.Hilti.C2C.Product.PirRebarSpacing',
                    TooltipType: TooltipType.Popup,
                    Command: 'OpenRebarSpacingPopup'
                },
                {
                    ControlType: 'Checkbox',
                    DisplayKey: 'Agito.Hilti.C2C.Product.Reinforcement.IncludeReinforcementCompression',
                    Name: 'Reinforcement.IncludeReinforcementCompression',
                    UIPropertyId: 10591,
                    TooltipType: TooltipType.Popup,
                    Command: 'OpenIncludeReinforcementCompressionPopup'
                },
                {
                    ControlType: 'Button',
                    ButtonSize: ButtonSize.Full,
                    ButtonType: ButtonType.Default,
                    Name: 'Reinforcement.DefineOtherParameters',
                    DisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.DefineOtherParameters',
                    UIPropertyId: 10633,
                    Command: 'OpenDefineOtherRebarParameters'
                }
            ]
        };
    }
    private createC2CMenu2dPir_ExistingReinforcement(design: DesignC2C): TabC2C {
        return {
            DisplayKey: 'Agito.Hilti.C2C.Navigation.ExistingReinforcement',
            Id: 132,
            Name: 'existingreinforcement-tab',
            Image: 'tab-existing-reinforcement',
            IconImage: getSpriteAsIconStyle('sprite-tab-existing-reinforcement'),
            Width: NavigationTabWidth.Normal,
            TabRegions: [
                this.createC2CMenu2dPir_ExistingReinforcement_Materials(design),
                this.createC2CMenu2dPir_ExistingReinforcement_LongitudinalReinforcement(),
                this.createC2CMenu2dPir_ExistingReinforcement_TransverseReinforcement(design)
            ]
        };
    }

    private createC2CMenu2dPir_ExistingReinforcement_Materials(design: DesignC2C): RegionC2C {
        return {
            DisplayKey: 'Agito.Hilti.C2C.Navigation.Reinforcement.Materials',
            Id: 27,
            Name: 'materials-section',
            Controls: [
                {
                    ControlType: 'TextBox',
                    Name: 'Material.ExistingReinforcementMaterialFyk',
                    TitleDisplayKey: isASBased(design) ? 'Agito.Hilti.C2C.Reinforcement.Fsy' : 'Agito.Hilti.C2C.Reinforcement.Fyk',
                    UIPropertyId: 10524,
                    UnitGroup: UnitGroup.Stress,
                }
            ]
        };
    }

    private createC2CMenu2dPir_ExistingReinforcement_LongitudinalReinforcement(): RegionC2C {
        return {
            DisplayKey: 'Agito.Hilti.C2C.Navigation.LongitudinalReinforcement',
            Id: 28,
            Name: 'longitudinal-reinforcement-section',
            Controls: [
                {
                    ControlType: 'Checkbox',
                    Name: 'LongitudinalReinforcement.LinkReinforcement',
                    DisplayKey: 'Agito.Hilti.C2C.Reinforcement.LongitudinalReinforcement.LinkReinforcement',
                    Size: 100,
                    UIPropertyId: 10740
                },
                {
                    CodelistName: 'LongitudinalReinforcementOption',
                    ControlType: 'Dropdown',
                    Name: 'LongitudinalReinforcement.Option',
                    Size: 100,
                    UIPropertyId: 10525
                },
                {
                    ControlType: 'TabGroup',
                    Name: 'longitudinal-reinforcement-layers-tab',
                    Tabs: [
                        {
                            DisplayKey: 'Agito.Hilti.C2C.Reinforcement.TopLayer',
                            Name: 'longitudinal-reinforcement-layers-tab-top-layer',
                            Tag: 'top-layer'
                        },
                        {
                            DisplayKey: 'Agito.Hilti.C2C.Reinforcement.BottomLayer',
                            Name: 'longitudinal-reinforcement-layers-tab-bottom-layer',
                            Tag: 'bottom-layer'
                        }
                    ]
                },
                {
                    CodelistName: 'ShapeType',
                    ControlType: 'RadioButtonGroup',
                    Name: 'LongitudinalReinforcement.Shape',
                    Size: 50,
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.LongitudinalReinforcement.Shape',
                    UIPropertyId: 10531,
                },
                {
                    CodelistName: 'BondCondition',
                    ControlType: 'RadioButtonGroup',
                    Name: 'LongitudinalReinforcement.BondCondition',
                    Size: 50,
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.LongitudinalReinforcement.BondCondition',
                    UIPropertyId: 10530,
                },
                {
                    CodelistName: 'GenericRebarDiameter',
                    ControlType: 'Dropdown',
                    Name: 'LongitudinalReinforcement.DiameterNew',
                    Size: 50,
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.LongitudinalReinforcement.Diameter',
                    UIPropertyId: 10526,
                    UnitGroup: UnitGroup.Length
                },
                {
                    ControlType: 'TextBox',
                    Name: 'LongitudinalReinforcement.NumberOfBars',
                    Size: 50,
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.LongitudinalReinforcement.NumberOfBars',
                    UIPropertyId: 10646
                },
                {
                    ControlType: 'TextBox',
                    Name: 'LongitudinalReinforcement.NumberOfbarsX',
                    Size: 50,
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.LongitudinalReinforcement.NumberOfBarsX',
                    UIPropertyId: 10527
                },
                {
                    ControlType: 'TextBox',
                    Name: 'LongitudinalReinforcement.NumberOfBarsY',
                    Size: 50,
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.LongitudinalReinforcement.NumberOfBarsY',
                    UIPropertyId: 10528
                },
                {
                    ControlType: 'TextBox',
                    Name: 'LongitudinalReinforcement.Cover',
                    Size: 50,
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.SideCover',
                    UIPropertyId: 10529,
                    UnitGroup: UnitGroup.Length
                },
                {
                    ControlType: 'TextBox',
                    Name: 'LongitudinalReinforcement.Arrangement.FrontCover',
                    Size: 50,
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.FrontCover',
                    UIPropertyId: 10709,
                    UnitGroup: UnitGroup.Length,
                },
                {
                    ControlType: 'Checkbox',
                    DisplayKey: 'Agito.Hilti.C2C.Reinforcement.TopLayer',
                    Name: 'LongitudinalReinforcement.TopLayer',
                    UIPropertyId: 10532,
                    ParentControlName: 'longitudinal-reinforcement-layers-tab',
                    ParentControlTag: 'top-layer'
                },
                {
                    ControlType: 'Dropdown',
                    Name: 'LongitudinalReinforcement.TopLayersCount',
                    UIPropertyId: 10533,
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.NumberOfLayers',
                    ParentControlName: 'longitudinal-reinforcement-layers-tab',
                    ParentControlTag: 'top-layer',
                    CodelistName: 'NumberOfLayers'
                },
                {
                    CodelistName: 'ShapeType',
                    ControlType: 'RadioButtonGroup',
                    Name: 'LongitudinalReinforcement.TopShapeType',
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.LongitudinalReinforcement.Shape',
                    UIPropertyId: 10541,
                    ParentControlName: 'longitudinal-reinforcement-layers-tab',
                    ParentControlTag: 'top-layer',
                    Size: 50
                },
                {
                    CodelistName: 'BondCondition',
                    ControlType: 'RadioButtonGroup',
                    Name: 'LongitudinalReinforcement.TopBondCondition',
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.BondCondition',
                    UIPropertyId: 10542,
                    ParentControlName: 'longitudinal-reinforcement-layers-tab',
                    ParentControlTag: 'top-layer',
                    Size: 50
                },
                {
                    ControlType: 'TextBox',
                    Name: 'LongitudinalReinforcement.Layer.Top.FrontCover',
                    Size: 50,
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.FrontCover',
                    UIPropertyId: 10708,
                    UnitGroup: UnitGroup.Length,
                    ParentControlName: 'longitudinal-reinforcement-layers-tab',
                    ParentControlTag: 'top-layer'
                },
                {
                    ControlType: 'Label',
                    Name: 'LongitudinalReinforcement.TopLayerOne',
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.LayerOne',
                    UIPropertyId: 10534,
                    ParentControlName: 'longitudinal-reinforcement-layers-tab',
                    ParentControlTag: 'top-layer',
                    Role: LabelRole.SubHeading
                },
                {
                    ControlType: 'Dropdown',
                    CodelistName: 'GenericRebarDiameter',
                    Name: 'LongitudinalReinforcement.TopDiameterl1',
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.Diameter',
                    UIPropertyId: 10535,
                    ParentControlName: 'longitudinal-reinforcement-layers-tab',
                    ParentControlTag: 'top-layer'
                },
                {
                    CodelistName: 'SpacingBarsOption',
                    ControlType: 'RadioButtonGroup',
                    Name: 'LongitudinalReinforcement.TopSpacingBarsl1',
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.BarsArrangement',
                    UIPropertyId: 10536,
                    ParentControlName: 'longitudinal-reinforcement-layers-tab',
                    ParentControlTag: 'top-layer',
                    Size: 50
                },
                {
                    ControlType: 'TextBox',
                    Name: 'LongitudinalReinforcement.TopBarsl1',
                    UIPropertyId: 10538,
                    ParentControlName: 'longitudinal-reinforcement-layers-tab',
                    ParentControlTag: 'top-layer',
                    Size: 50
                },
                {
                    ControlType: 'TextBox',
                    Name: 'LongitudinalReinforcement.TopSpacingl1',
                    UIPropertyId: 10537,
                    UnitGroup: UnitGroup.Length,
                    ParentControlName: 'longitudinal-reinforcement-layers-tab',
                    ParentControlTag: 'top-layer',
                    Size: 50
                },
                {
                    ControlType: 'TextBox',
                    Name: 'LongitudinalReinforcement.TopTopCoverl1',
                    UIPropertyId: 10539,
                    UnitGroup: UnitGroup.Length,
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.TopCover',
                    ParentControlName: 'longitudinal-reinforcement-layers-tab',
                    ParentControlTag: 'top-layer',
                    Size: 50
                },
                {
                    ControlType: 'TextBox',
                    Name: 'LongitudinalReinforcement.TopSideCoverl1',
                    UIPropertyId: 10540,
                    UnitGroup: UnitGroup.Length,
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.SideCover',
                    ParentControlName: 'longitudinal-reinforcement-layers-tab',
                    ParentControlTag: 'top-layer',
                    Size: 50
                },
                {
                    ControlType: 'TextBox',
                    Name: 'LongitudinalReinforcement.TopMinSideCoverl1',
                    UIPropertyId: 10744,
                    UnitGroup: UnitGroup.Length,
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.MinSideCover',
                    ParentControlName: 'longitudinal-reinforcement-layers-tab',
                    ParentControlTag: 'top-layer',
                    Size: 50
                },
                {
                    ControlType: 'Label',
                    Name: 'LongitudinalReinforcement.TopLayerTwo',
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.LayerTwo',
                    UIPropertyId: 10543,
                    ParentControlName: 'longitudinal-reinforcement-layers-tab',
                    ParentControlTag: 'top-layer',
                    Role: LabelRole.SubHeading
                },
                {
                    ControlType: 'Dropdown',
                    CodelistName: 'GenericRebarDiameter',
                    Name: 'LongitudinalReinforcement.TopDiameterl2',
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.Diameter',
                    UIPropertyId: 10544,
                    ParentControlName: 'longitudinal-reinforcement-layers-tab',
                    ParentControlTag: 'top-layer'
                },
                {
                    ControlType: 'RadioButtonGroup',
                    CodelistName: 'SpacingBarsOption',
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.BarsArrangement',
                    Name: 'LongitudinalReinforcement.TopSpacingBarsl2',
                    UIPropertyId: 10545,
                    ParentControlName: 'longitudinal-reinforcement-layers-tab',
                    ParentControlTag: 'top-layer',
                    Size: 50
                },
                {
                    ControlType: 'TextBox',
                    Name: 'LongitudinalReinforcement.TopBarsl2',
                    UIPropertyId: 10547,
                    ParentControlName: 'longitudinal-reinforcement-layers-tab',
                    ParentControlTag: 'top-layer',
                    Size: 50
                },
                {
                    ControlType: 'TextBox',
                    Name: 'LongitudinalReinforcement.TopSpacingl2',
                    UIPropertyId: 10546,
                    UnitGroup: UnitGroup.Length,
                    ParentControlName: 'longitudinal-reinforcement-layers-tab',
                    ParentControlTag: 'top-layer',
                    Size: 50
                },
                {
                    ControlType: 'TextBox',
                    Name: 'LongitudinalReinforcement.TopTopCoverl2',
                    UIPropertyId: 10548,
                    UnitGroup: UnitGroup.Length,
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.TopCover',
                    ParentControlName: 'longitudinal-reinforcement-layers-tab',
                    ParentControlTag: 'top-layer',
                    Size: 50
                },
                {
                    ControlType: 'TextBox',
                    Name: 'LongitudinalReinforcement.TopSideCoverl2',
                    UIPropertyId: 10549,
                    UnitGroup: UnitGroup.Length,
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.SideCover',
                    ParentControlName: 'longitudinal-reinforcement-layers-tab',
                    ParentControlTag: 'top-layer',
                    Size: 50
                },
                {
                    ControlType: 'TextBox',
                    Name: 'LongitudinalReinforcement.TopMinSideCoverl2',
                    UIPropertyId: 10745,
                    UnitGroup: UnitGroup.Length,
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.MinSideCover',
                    ParentControlName: 'longitudinal-reinforcement-layers-tab',
                    ParentControlTag: 'top-layer',
                    Size: 50
                },
                {
                    ControlType: 'Checkbox',
                    DisplayKey: 'Agito.Hilti.C2C.Reinforcement.BottomLayer',
                    Name: 'LongitudinalReinforcement.BottomLayer',
                    UIPropertyId: 10552,
                    ParentControlName: 'longitudinal-reinforcement-layers-tab',
                    ParentControlTag: 'bottom-layer'
                },
                {
                    ControlType: 'Dropdown',
                    Name: 'LongitudinalReinforcement.BottomLayersCount',
                    UIPropertyId: 10553,
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.NumberOfLayers',
                    ParentControlName: 'longitudinal-reinforcement-layers-tab',
                    ParentControlTag: 'bottom-layer',
                    CodelistName: 'NumberOfLayers'
                },
                {
                    CodelistName: 'ShapeType',
                    ControlType: 'RadioButtonGroup',
                    Name: 'LongitudinalReinforcement.BottomShapeType',
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.LongitudinalReinforcement.Shape',
                    UIPropertyId: 10561,
                    ParentControlName: 'longitudinal-reinforcement-layers-tab',
                    ParentControlTag: 'bottom-layer',
                    Size: 50
                },
                {
                    CodelistName: 'BondCondition',
                    ControlType: 'RadioButtonGroup',
                    Name: 'LongitudinalReinforcement.BottomBondCondition',
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.BondCondition',
                    UIPropertyId: 10562,
                    ParentControlName: 'longitudinal-reinforcement-layers-tab',
                    ParentControlTag: 'bottom-layer',
                    Size: 50
                },
                {
                    ControlType: 'TextBox',
                    Name: 'LongitudinalReinforcement.Layer.Bottom.FrontCover',
                    Size: 50,
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.FrontCover',
                    UIPropertyId: 10708,
                    UnitGroup: UnitGroup.Length,
                    ParentControlName: 'longitudinal-reinforcement-layers-tab',
                    ParentControlTag: 'bottom-layer'
                },
                {
                    ControlType: 'Label',
                    Name: 'LongitudinalReinforcement.BottomLayerOne',
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.LayerOne',
                    UIPropertyId: 10554,
                    ParentControlName: 'longitudinal-reinforcement-layers-tab',
                    ParentControlTag: 'bottom-layer',
                    Role: LabelRole.SubHeading
                },
                {
                    ControlType: 'Dropdown',
                    CodelistName: 'GenericRebarDiameter',
                    Name: 'LongitudinalReinforcement.BottomDiameterl1',
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.Diameter',
                    UIPropertyId: 10555,
                    ParentControlName: 'longitudinal-reinforcement-layers-tab',
                    ParentControlTag: 'bottom-layer'
                },
                {
                    CodelistName: 'SpacingBarsOption',
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.BarsArrangement',
                    ControlType: 'RadioButtonGroup',
                    Name: 'LongitudinalReinforcement.BottomSpacingBarsl1',
                    UIPropertyId: 10556,
                    ParentControlName: 'longitudinal-reinforcement-layers-tab',
                    ParentControlTag: 'bottom-layer',
                    Size: 50
                },
                {
                    ControlType: 'TextBox',
                    Name: 'LongitudinalReinforcement.BottomSpacingl1',
                    UIPropertyId: 10557,
                    UnitGroup: UnitGroup.Length,
                    ParentControlName: 'longitudinal-reinforcement-layers-tab',
                    ParentControlTag: 'bottom-layer',
                    Size: 50
                },
                {
                    ControlType: 'TextBox',
                    Name: 'LongitudinalReinforcement.BottomBarsl1',
                    UIPropertyId: 10558,
                    ParentControlName: 'longitudinal-reinforcement-layers-tab',
                    ParentControlTag: 'bottom-layer',
                    Size: 50
                },
                {
                    ControlType: 'TextBox',
                    Name: 'LongitudinalReinforcement.BottomTopCoverl1',
                    UIPropertyId: 10559,
                    UnitGroup: UnitGroup.Length,
                    TitleDisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.BottomCover',
                    ParentControlName: 'longitudinal-reinforcement-layers-tab',
                    ParentControlTag: 'bottom-layer',
                    Size: 50
                },
                {
                    ControlType: 'TextBox',
                    Name: 'LongitudinalReinforcement.BottomSideCoverl1',
                    UIPropertyId: 10560,
                    UnitGroup: UnitGroup.Length,
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.SideCover',
                    ParentControlName: 'longitudinal-reinforcement-layers-tab',
                    ParentControlTag: 'bottom-layer',
                    Size: 50
                },
                {
                    ControlType: 'TextBox',
                    Name: 'LongitudinalReinforcement.BottomMinSideCoverl1',
                    UIPropertyId: 10746,
                    UnitGroup: UnitGroup.Length,
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.MinSideCover',
                    ParentControlName: 'longitudinal-reinforcement-layers-tab',
                    ParentControlTag: 'bottom-layer',
                    Size: 50
                },
                {
                    ControlType: 'Label',
                    Name: 'LongitudinalReinforcement.BottomLayerTwo',
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.LayerTwo',
                    UIPropertyId: 10563,
                    ParentControlName: 'longitudinal-reinforcement-layers-tab',
                    ParentControlTag: 'bottom-layer',
                    Role: LabelRole.SubHeading
                },
                {
                    ControlType: 'Dropdown',
                    CodelistName: 'GenericRebarDiameter',
                    Name: 'LongitudinalReinforcement.BottomDiameterl2',
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.Diameter',
                    UIPropertyId: 10564,
                    ParentControlName: 'longitudinal-reinforcement-layers-tab',
                    ParentControlTag: 'bottom-layer'
                },
                {
                    CodelistName: 'SpacingBarsOption',
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.BarsArrangement',
                    ControlType: 'RadioButtonGroup',
                    Name: 'LongitudinalReinforcement.BottomSpacingBarsl2',
                    UIPropertyId: 10565,
                    ParentControlName: 'longitudinal-reinforcement-layers-tab',
                    ParentControlTag: 'bottom-layer',
                    Size: 50
                },
                {
                    ControlType: 'TextBox',
                    Name: 'LongitudinalReinforcement.BottomSpacingl2',
                    UIPropertyId: 10566,
                    UnitGroup: UnitGroup.Length,
                    ParentControlName: 'longitudinal-reinforcement-layers-tab',
                    ParentControlTag: 'bottom-layer',
                    Size: 50
                },
                {
                    ControlType: 'TextBox',
                    Name: 'LongitudinalReinforcement.BottomBarsl2',
                    UIPropertyId: 10567,
                    ParentControlName: 'longitudinal-reinforcement-layers-tab',
                    ParentControlTag: 'bottom-layer',
                    Size: 50
                },
                {
                    ControlType: 'TextBox',
                    Name: 'LongitudinalReinforcement.BottomTopCoverl2',
                    UIPropertyId: 10568,
                    UnitGroup: UnitGroup.Length,
                    TitleDisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.BottomCover',
                    ParentControlName: 'longitudinal-reinforcement-layers-tab',
                    ParentControlTag: 'bottom-layer',
                    Size: 50
                },
                {
                    ControlType: 'TextBox',
                    Name: 'LongitudinalReinforcement.BottomSideCoverl2',
                    UIPropertyId: 10569,
                    UnitGroup: UnitGroup.Length,
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.SideCover',
                    ParentControlName: 'longitudinal-reinforcement-layers-tab',
                    ParentControlTag: 'bottom-layer',
                    Size: 50
                },
                {
                    ControlType: 'TextBox',
                    Name: 'LongitudinalReinforcement.BottomMinSideCoverl2',
                    UIPropertyId: 10747,
                    UnitGroup: UnitGroup.Length,
                    TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.MinSideCover',
                    ParentControlName: 'longitudinal-reinforcement-layers-tab',
                    ParentControlTag: 'bottom-layer',
                    Size: 50
                }
            ]
        };
    }

    private createC2CMenu2dPir_ExistingReinforcement_TransverseReinforcement(design: DesignC2C): RegionC2C {
        return {
            DisplayKey: 'Agito.Hilti.C2C.Navigation.TransverseReinforcement',
            Id: 29,
            Name: 'transverse-reinforcement-section',
            Controls: [
                {
                    ControlType: 'Checkbox',
                    DisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.Define',
                    Name: 'TransverseReinforcement.Define',
                    UIPropertyId: 10593,
                    TooltipType: TooltipType.Popup,
                    Command: 'OpenTransverseReinforcementDescriptionPopup'
                },
                {
                    CodelistName: 'TransverseReinforcementOption',
                    ControlType: 'Dropdown',
                    Name: 'TransverseReinforcement.Options',
                    UIPropertyId: 10594
                },
                {
                    ControlType: 'TabGroup',
                    Name: 'transverse-reinforcement-layers-tab',
                    Tabs: [
                        {
                            DisplayKey: 'Agito.Hilti.C2C.Reinforcement.TopLayer',
                            Name: 'transverse-reinforcement-layers-tab-top-layer',
                            Tag: 'top-layer'
                        },
                        {
                            DisplayKey: 'Agito.Hilti.C2C.Reinforcement.BottomLayer',
                            Name: 'transverse-reinforcement-layers-tab-bottom-layer',
                            Tag: 'bottom-layer'
                        }
                    ]
                },
                {
                    ControlType: 'Label',
                    Name: 'TransverseReinforcement.TopLayerOne',
                    TitleDisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.LayerOne',
                    UIPropertyId: 10615,
                    ParentControlName: 'transverse-reinforcement-layers-tab',
                    ParentControlTag: 'top-layer',
                    Role: LabelRole.SubHeading
                },
                {
                    ControlType: 'Dropdown',
                    CodelistName: 'GenericRebarDiameter',
                    Name: 'TransverseReinforcement.TopDiameterl1',
                    TitleDisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.Diameter',
                    Size: 50,
                    UnitGroup: UnitGroup.Length,
                    UIPropertyId: 10597,
                    ParentControlName: 'transverse-reinforcement-layers-tab',
                    ParentControlTag: 'top-layer'
                },
                {
                    ControlType: 'TextBox',
                    Name: 'TransverseReinforcement.TopSpacingl1',
                    TitleDisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.Spacing2',
                    Size: 50,
                    UnitGroup: UnitGroup.Length,
                    UIPropertyId: 10598,
                    ParentControlName: 'transverse-reinforcement-layers-tab',
                    ParentControlTag: 'top-layer'
                },
                {
                    ControlType: 'TextBox',
                    Name: 'TransverseReinforcement.TopCoverl1',
                    TitleDisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.TopCover',
                    Size: 50,
                    UnitGroup: UnitGroup.Length,
                    UIPropertyId: 10599,
                    ParentControlName: 'transverse-reinforcement-layers-tab',
                    ParentControlTag: 'top-layer'
                },
                {
                    ControlType: 'Label',
                    Name: 'TransverseReinforcement.TopLayerTwo',
                    TitleDisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.LayerTwo',
                    UIPropertyId: 10616,
                    ParentControlName: 'transverse-reinforcement-layers-tab',
                    ParentControlTag: 'top-layer',
                    Role: LabelRole.SubHeading
                },
                {
                    ControlType: 'Dropdown',
                    CodelistName: 'GenericRebarDiameter',
                    Name: 'TransverseReinforcement.TopDiameterl2',
                    TitleDisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.Diameter',
                    Size: 50,
                    UnitGroup: UnitGroup.Length,
                    UIPropertyId: 10600,
                    ParentControlName: 'transverse-reinforcement-layers-tab',
                    ParentControlTag: 'top-layer'
                },
                {
                    ControlType: 'TextBox',
                    Name: 'TransverseReinforcement.TopSpacingl2',
                    TitleDisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.Spacing2',
                    Size: 50,
                    UnitGroup: UnitGroup.Length,
                    UIPropertyId: 10601,
                    ParentControlName: 'transverse-reinforcement-layers-tab',
                    ParentControlTag: 'top-layer'
                },
                {
                    ControlType: 'TextBox',
                    Name: 'TransverseReinforcement.TopCoverl2',
                    TitleDisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.TopCover',
                    Size: 50,
                    UnitGroup: UnitGroup.Length,
                    UIPropertyId: 10602,
                    ParentControlName: 'transverse-reinforcement-layers-tab',
                    ParentControlTag: 'top-layer'
                },
                {
                    ControlType: 'Label',
                    Name: 'TransverseReinforcement.BottomLayerOne',
                    TitleDisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.LayerOne',
                    UIPropertyId: 10617,
                    ParentControlName: 'transverse-reinforcement-layers-tab',
                    ParentControlTag: 'bottom-layer',
                    Role: LabelRole.SubHeading
                },
                {
                    ControlType: 'Dropdown',
                    CodelistName: 'GenericRebarDiameter',
                    Name: 'TransverseReinforcement.BottomDiameterl1',
                    TitleDisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.Diameter',
                    Size: 50,
                    UnitGroup: UnitGroup.Length,
                    UIPropertyId: 10605,
                    ParentControlName: 'transverse-reinforcement-layers-tab',
                    ParentControlTag: 'bottom-layer'
                },
                {
                    ControlType: 'TextBox',
                    Name: 'TransverseReinforcement.BottomSpacingl1',
                    TitleDisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.Spacing2',
                    Size: 50,
                    UnitGroup: UnitGroup.Length,
                    UIPropertyId: 10606,
                    ParentControlName: 'transverse-reinforcement-layers-tab',
                    ParentControlTag: 'bottom-layer'
                },
                {
                    ControlType: 'TextBox',
                    Name: 'TransverseReinforcement.BottomCoverl1',
                    TitleDisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.BottomCover',
                    Size: 50,
                    UnitGroup: UnitGroup.Length,
                    UIPropertyId: 10607,
                    ParentControlName: 'transverse-reinforcement-layers-tab',
                    ParentControlTag: 'bottom-layer'
                },
                {
                    ControlType: 'Label',
                    Name: 'TransverseReinforcement.BottomLayerTwo',
                    TitleDisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.LayerTwo',
                    UIPropertyId: 10618,
                    ParentControlName: 'transverse-reinforcement-layers-tab',
                    ParentControlTag: 'bottom-layer',
                    Role: LabelRole.SubHeading
                },
                {
                    ControlType: 'Dropdown',
                    CodelistName: 'GenericRebarDiameter',
                    Name: 'TransverseReinforcement.BottomDiameterl2',
                    TitleDisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.Diameter',
                    Size: 50,
                    UnitGroup: UnitGroup.Length,
                    UIPropertyId: 10608,
                    ParentControlName: 'transverse-reinforcement-layers-tab',
                    ParentControlTag: 'bottom-layer'
                },
                {
                    ControlType: 'TextBox',
                    Name: 'TransverseReinforcement.BottomSpacingl2',
                    TitleDisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.Spacing2',
                    Size: 50,
                    UnitGroup: UnitGroup.Length,
                    UIPropertyId: 10609,
                    ParentControlName: 'transverse-reinforcement-layers-tab',
                    ParentControlTag: 'bottom-layer'
                },
                {
                    ControlType: 'TextBox',
                    Name: 'TransverseReinforcement.BottomCoverl2',
                    TitleDisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.BottomCover',
                    Size: 50,
                    UnitGroup: UnitGroup.Length,
                    UIPropertyId: 10610,
                    ParentControlName: 'transverse-reinforcement-layers-tab',
                    ParentControlTag: 'bottom-layer'
                },
                {
                    ControlType: 'Dropdown',
                    CodelistName: 'GenericRebarDiameter',
                    Name: 'TransverseReinforcement.StirrupsDiameter',
                    TitleDisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.Diameter',
                    Size: 50,
                    UnitGroup: UnitGroup.Length,
                    UIPropertyId: 10611
                },
                {
                    ControlType: 'TextBox',
                    Name: 'TransverseReinforcement.StirrupsSpacing',
                    TitleDisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.Spacing2',
                    Size: 50,
                    UnitGroup: UnitGroup.Length,
                    UIPropertyId: 10612
                },
                {
                    ControlType: 'TextBox',
                    Name: 'TransverseReinforcement.StirrupsCover',
                    TitleDisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.Cover',
                    Size: 50,
                    UnitGroup: UnitGroup.Length,
                    UIPropertyId: 10614
                },
                {
                    ControlType: 'Button',
                    ButtonSize: ButtonSize.Full,
                    ButtonType: ButtonType.Default,
                    Name: 'TransverseReinforcement.DefineOtherParameters',
                    DisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.DefineOtherParameters',
                    UIPropertyId: 10613,
                    Command: 'OpenDefineOtherRebarParameters'
                },
                {
                    ControlType: 'Checkbox',
                    DisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.DetailedDefinition',
                    Name: 'TransverseReinforcement.DetailedDefinition',
                    UIPropertyId: 10409,
                    TooltipType: getPopupTooltipConditional(isACICSA(design)),
                    Command: getCommandConditional('OpenDetailedDefinitionPopup', isACICSA(design))
                },
                {
                    ControlType: 'TextBox',
                    Name: 'TransverseReinforcement.TotalCrossSectionalArea',
                    TitleDisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.TotalCrossSectionalArea',
                    UIPropertyId: 10410,
                    UnitGroup: UnitGroup.Area,
                },
                {
                    ControlType: 'TextBox',
                    Name: 'TransverseReinforcement.Spacing',
                    TitleDisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.Spacing',
                    UIPropertyId: 10411,
                    UnitGroup: UnitGroup.Length,
                },
                {
                    ControlType: 'TextBox',
                    Name: 'TransverseReinforcement.NumberOfBars',
                    UIPropertyId: 10412,
                    TitleDisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.NumberOfBars'
                },
                {
                    ControlType: 'TextBox',
                    Name: 'TransverseReinforcement.Ktr',
                    UIPropertyId: 10413,
                    TitleDisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.Ktr',
                    UnitGroup: UnitGroup.Length
                },
                {
                    ControlType: 'Checkbox',
                    DisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.UnfavorableTolerance',
                    Name: 'TransverseReinforcement.UnfavorableTolerance',
                    UIPropertyId: 10683,
                    TooltipType: TooltipType.Popup,
                    Command: 'OpenTransverseReinforcementUnfavorableTolerancePopup'
                }
            ]
        };
    }

    private createC2CMenu2d_Coordinates2d(design: DesignC2C, unitService: UnitService): TabC2C {
        return {
            DisplayKey: 'Agito.Hilti.C2C.Navigation.Coordinates2d',
            Id: 11,
            Name: '2d-coordinates',
            Image: 'tab-edit-2d-coordinates',
            IconImage: getSpriteAsIconStyle('sprite-tab-edit-2d-coordinates'),
            Width: NavigationTabWidth.Extended,
            TabRegions: [
                this.createC2CMenu2d_Coordinates2d_RebarMaterial(design),
                this.createC2CMenu2dPir_Product_CalculationMode_RebarPosition(),
                this.createC2CMenu2d_Coordinates2d_ExistingReinforcement(design, unitService),
                this.createC2CMenu2d_Coordinates2d_PostInstallReinforcement(unitService)
            ]
        };
    }

    private createC2CMenu2d_Edit2d(): TabC2C {
        return {
            DisplayKey: 'Agito.Hilti.Profis3.Navigation.TabEdit2d',
            Id: 10,
            Name: 'layout',
            Image: 'tab-edit-2d',
            IconImage: getSpriteAsIconStyle('sprite-tab-edit-2d'),
            Width: NavigationTabWidth.Normal,
            TabRegions: [
                this.createC2CMenu2d_Edit2d_Canvas()
            ]
        };
    }

    private createC2CMenu2d_Edit2d_Canvas(): RegionC2C {
        return {
            DisplayKey: 'Agito.Hilti.Profis3.Navigation.TabEdit2d.Canvas',
            Id: 11,
            Name: 'canvas',
            PreventFavorites: true,
            Controls: [
                {
                    ControlType: 'TextBox',
                    TitleDisplayKey: 'Agito.Hilti.Profis3.Navigation.TabEdit2d.Canvas.ControlGridSpacing',
                    Name: 'Canvas.GridSpacing'
                },
                {
                    ControlType: 'Checkbox',
                    DisplayKey: 'Agito.Hilti.Profis3.Navigation.TabEdit2d.Canvas.ControlShowGrid',
                    Name: 'Canvas.ShowGrid'
                }
            ]
        };
    }

    private createC2CMenu2d_Coordinates2d_RebarMaterial(design: DesignC2C): RegionC2C {
        return {
            Id: 15,
            DisplayKey: 'Agito.Hilti.C2C.Navigation.Coordinates2d.RebarMaterial',
            Name: 'rebar-material',
            PreventFavorites: true,
            Controls: [
                {
                    ControlType: 'TextBox',
                    Name: 'Material.RebarMaterialFyk',
                    UnitGroup: UnitGroup.Stress,
                    TitleDisplayKey: isASBased(design) ? 'Agito.Hilti.C2C.Reinforcement.Fsy' : 'Agito.Hilti.C2C.Reinforcement.Fyk',
                    UIPropertyId: 10728
                }
            ]
        };
    }

    private createC2CMenu2dPir_Product_CalculationMode_RebarPosition(): RegionC2C {
        return {
            Id: 93,
            Name: 'calculation-mode-coordinates-tab',
            DisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.CalculationMode',
            Controls: [
                {
                    ControlType: 'Dropdown',
                    Name: 'CalculationMode.Dropdown1',
                    CodelistName: 'CalculationMode',
                    UIPropertyId: 10570,
                    TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabProduct.CalculationMode.RebarPosition',
                    TooltipType: TooltipType.Popup,
                    Command: 'OpenRebarPositionPopup'
                }
            ]
        };
    }

    private createC2CMenu2d_Coordinates2d_ExistingReinforcement(design: DesignC2C, unitService: UnitService): RegionC2C {
        return {
            DisplayKey: (isPirEU(design) && checkConnectionType(design, ConnectionType.StructuralJoints)) ?
                'Agito.Hilti.C2C.Navigation.Reinforcement.ConcreteReinforcement' : 'Agito.Hilti.C2C.Navigation.Coordinates2d.ExistingReinforcement',
            Name: 'existing-refinforcement',
            Id: 13,
            PreventFavorites: true,
            Controls: [
                {
                    ControlType: 'CustomControl',
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    initialize: (controlProps: IAdvancedPointsTableProps, _modals: Record<number, (input?: object) => IModalOpened>, _setState: (fn?: (prevMenu: IMenu) => IMenu) => IMenu) => initializeAdvancedPointsTable(controlProps, unitService),
                    Name: 'ExistingReinforcement.ExistingReinforcement'
                } as unknown as CustomControl<IAdvancedPointsTableProps, MainMenuCommandC2C>,
            ]
        };
    }

    private createC2CMenu2d_Coordinates2d_PostInstallReinforcement(unitService: UnitService): RegionC2C {
        return {
            DisplayKey: 'Agito.Hilti.C2C.Navigation.Coordinates2d.PostInstallReinforcement',
            Name: 'post-install-reinforcement',
            Id: 14,
            PreventFavorites: true,
            Controls: [
                {
                    ControlType: 'CustomControl',
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    initialize: (controlProps: IAdvancedPointsTableProps, _modals: Record<number, (input?: object) => IModalOpened>, _setState: (fn?: (prevMenu: IMenu) => IMenu) => IMenu) => initializeAdvancedPointsTable(controlProps, unitService),
                    Name: 'PostInstalledRebar.PostInstalledRebar'
                } as unknown as CustomControl<IAdvancedPointsTableProps, MainMenuCommandC2C>,
                {
                    ControlType: 'Button',
                    ButtonSize: ButtonSize.Full,
                    ButtonType: ButtonType.Default,
                    Name: 'post-install-reinforcement-other-parameters',
                    DisplayKey: 'Agito.Hilti.C2C.Coordinates2d.PostInstallReinforcement.OtherParameters',
                    UIPropertyId: 10678,
                    Command: 'OpenDesignSettingsPopup'
                }
            ]
        };
    }

    private creacteC2CMenu2d_Product_TransverseReinforcement(): RegionC2C {
        return {
            DisplayKey: 'Agito.Hilti.C2C.Navigation.TransverseReinforcement',
            Id: 95,
            Name: 'product-transverse-reinforcement-section',
            Controls: [
                {
                    ControlType: 'Checkbox',
                    DisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.Define',
                    Name: 'product-transverse-reinforcement-define',
                    UIPropertyId: 10820,
                    TooltipType: TooltipType.Popup,
                    Command: 'OpenProductTransverseReinforcementPopup'
                },
                {
                    ControlType: 'Dropdown',
                    CodelistName: 'GenericRebarDiameter',
                    Name: 'transverse-reinforcement-stirrup-diameter-id',
                    TitleDisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.Diameter',
                    Size: 50,
                    UnitGroup: UnitGroup.Length,
                    UIPropertyId: 10821
                },
                {
                    ControlType: 'TextBox',
                    Name: 'product-transverse-reinforcement-spacing',
                    TitleDisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.Spacing2',
                    UIPropertyId: 10823,
                    Size: 50,
                    UnitGroup: UnitGroup.Length
                },
                {
                    ControlType: 'TextBox',
                    Name: 'product-transverse-reinforcement-number-of-stirrup-legs',
                    TitleDisplayKey: 'Agito.Hilti.C2C.ProductTransverseReinforcement.NumberOfStirrupLegs',
                    UIPropertyId: 10822,
                    Size: 50,
                }
            ]
        };
    }

    private fillControlProperties(menu: MenuC2C): void {

        const allControls = menu.Tabs.flatMap(tab => tab.TabRegions).flatMap(region => region.Controls);
        allControls.forEach(control => {
            control.Size = control.Size ?? 100;
            control.TooltipType = control.TooltipType ?? TooltipType.Normal;
        });
    }
}

import {
    ButtonGroupType, ButtonSize, ButtonType, NavigationTabWidth, TooltipType
} from '@profis-engineering/pe-ui-common/entities/main-menu/navigation';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';

import { DesignC2C } from '../../../shared/entities/design-c2c';
import { RegionC2C, TabC2C } from '../../../shared/services/static-menu.service.base';
import { getSpriteAsIconStyle } from '../../sprites';
import {
    getCommandConditional, getPopupTooltipConditional, isACICSA, isOverlay, isThinOverlay
} from './static-menu-helper';

export function createC2CMenu_OverlayConcreteBlockDefinition(design: DesignC2C): TabC2C {
    return {
        DisplayKey: 'Agito.Hilti.C2C.Navigation.ConcreteBlockDefinitionTab',
        Id: 21,
        Name: 'overlay-concreteblockdefinition-tab',
        Image: 'tab-overlay-layout',
        IconImage: getSpriteAsIconStyle('sprite-tab-overlay-layout'),
        Width: NavigationTabWidth.Normal,
        TabRegions: isOverlay(design) && isACICSA(design)
            ? createC2CMenu_OverlayConcreteBlockDefinition_HNARegions(design)
            : createC2CMenu_OverlayConcreteBlockDefinition_Regions(design)
    };
}

function createC2CMenu_OverlayConcreteBlockDefinition_Regions(design: DesignC2C) {
    return ([
        createC2CMenu_OverlayInstallationCondition_BaseMaterial(design),
        createC2CMenu_OverlayConcreteBlockDefinition_Geometry(design),
        createC2CMenu_OverlayConcreteBlockDefinition_Zones(),
        createC2CMenu_OverlayConcreteBlockDefinition_Reinforcement(design),
        createC2CMenu_OverlayConcreteBlockDefinition_ModelRotation()
    ]);
}

function createC2CMenu_OverlayConcreteBlockDefinition_HNARegions(design: DesignC2C) {
    return ([
        createC2CMenu_OverlayConcreteBlockDefinition_Geometry(design),
        createC2CMenu_OverlayConcreteBlockDefinition_Zones(),
        createC2CMenu_OverlayConcreteBlockDefinition_Reinforcement(design),
        createC2CMenu_OverlayConcreteBlockDefinition_ModelRotation()
    ]);
}


function createC2CMenu_OverlayConcreteBlockDefinition_Geometry(design: DesignC2C): RegionC2C {
    return {
        DisplayKey: 'Agito.Hilti.Profis3.Navigation.TabBaseMaterial.RegionGeometry',
        Id: 20,
        Name: 'geometry-section',
        Controls: [
            {
                ControlType: 'TextBox',
                UnitGroup: 100,
                Name: 'slabwidth-input',
                Size: 50,
                DisplayKey: 'Agito.Hilti.C2C.Overlay.Geometry.SlabWidth',
                TitleDisplayKey: 'Agito.Hilti.C2C.Overlay.Geometry.SlabWidth',
                UIPropertyId: 10042,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            },
            {
                ControlType: 'TextBox',
                UnitGroup: 100,
                Name: 'slablength-input',
                Size: 50,
                DisplayKey: 'Agito.Hilti.C2C.Overlay.Geometry.SlabLength',
                TitleDisplayKey: 'Agito.Hilti.C2C.Overlay.Geometry.SlabLength',
                UIPropertyId: 10045,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            },
            {
                ControlType: 'TextBox',
                UnitGroup: 100,
                Name: 'genericwidth-input',
                Size: 50,
                DisplayKey: isACICSA(design) ? 'Agito.Hilti.C2C.Overlay.Geometry.SlabWidth.HNA' : 'Agito.Hilti.C2C.Overlay.Geometry.SlabWidth',
                TitleDisplayKey: isACICSA(design) ? 'Agito.Hilti.C2C.Overlay.Geometry.SlabWidth.HNA' : 'Agito.Hilti.C2C.Overlay.Geometry.SlabWidth',
                UIPropertyId: 10139,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            },
            {
                ControlType: 'TextBox',
                UnitGroup: 100,
                Name: 'genericlength-input',
                Size: 50,
                DisplayKey: isACICSA(design) ? 'Agito.Hilti.C2C.Overlay.Geometry.SlabLength.HNA' : 'Agito.Hilti.C2C.Overlay.Geometry.SlabLength',
                TitleDisplayKey: isACICSA(design) ? 'Agito.Hilti.C2C.Overlay.Geometry.SlabLength.HNA' : 'Agito.Hilti.C2C.Overlay.Geometry.SlabLength',
                UIPropertyId: 10140,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            },
            {
                ControlType: 'TextBox',
                UnitGroup: 100,
                Name: 'beamwidth-input',
                Size: 50,
                DisplayKey: 'Agito.Hilti.C2C.Overlay.Geometry.BeamWidth',
                TitleDisplayKey: 'Agito.Hilti.C2C.Overlay.Geometry.BeamWidth',
                UIPropertyId: 10043,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            },
            {
                ControlType: 'TextBox',
                UnitGroup: 100,
                Name: 'beamlength-input',
                Size: 50,
                DisplayKey: 'Agito.Hilti.C2C.Overlay.Geometry.BeamLength',
                TitleDisplayKey: 'Agito.Hilti.C2C.Overlay.Geometry.BeamLength',
                UIPropertyId: 10046,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            },
            {
                ControlType: 'TextBox',
                Name: 'wallwidth-input',
                Size: 50,
                DisplayKey: 'Agito.Hilti.C2C.Overlay.Geometry.WallWidth',
                TitleDisplayKey: 'Agito.Hilti.C2C.Overlay.Geometry.WallWidth',
                UIPropertyId: 10044,
                UnitGroup: 100,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            },
            {
                ControlType: 'TextBox',
                UnitGroup: 100,
                Name: 'wallheight-input',
                Size: 50,
                DisplayKey: 'Agito.Hilti.C2C.Overlay.Geometry.WallHeight',
                TitleDisplayKey: 'Agito.Hilti.C2C.Overlay.Geometry.WallHeight',
                UIPropertyId: 10047,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            },
            {
                ControlType: 'Group',
                Name: 'area-concrete-shear-interface',
                DisplayKey: 'Agito.Hilti.C2C.Overlay.Geometry.ConcreteShearInterface.Title',
                TooltipDisplayKey: 'Agito.Hilti.C2C.Overlay.Geometry.ConcreteShearInterface.Title',
                TitleDisplayKey: 'Agito.Hilti.C2C.Overlay.Geometry.ConcreteShearInterface.Title',
                TooltipTitleDisplayKey: 'Agito.Hilti.C2C.Overlay.Geometry.ConcreteShearInterface.Title',
                UIPropertyId: 10282
            },
            {
                ControlType: 'ToggleButtonGroup',
                ButtonGroupType: ButtonGroupType.Single,
                ButtonGroupUnselectable: true,
                Name: 'areaofconcreteshearinterface-mode',
                UIPropertyId: 10283,
                CodelistName: 'ConcreteShearInterfaceMode',
                ParentControlName: 'area-concrete-shear-interface'
            },
            {
                ControlType: 'TextBox',
                Name: 'areaofconcreteshearinterface',
                UIPropertyId: 10284,
                ParentControlName: 'area-concrete-shear-interface',
                UnitGroup: UnitGroup.Area
            },
            {
                ControlType: 'Group',
                Name: 'ExistingConcreteBlockGroup',
                TitleDisplayKey: 'Agito.Hilti.C2C.Overlay.Geometry.ExistingConcreteBlock',
                DisplayKey: 'Agito.Hilti.C2C.Overlay.Geometry.ExistingConcreteBlock',
                MultilineGroup: true
            },
            {
                ControlType: 'TextBox',
                UnitGroup: 100,
                Name: 'existing-concretewidth-input-textbox',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.Overlay.Geometry.ExistingWidth',
                UIPropertyId: 10818,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: '',
                ParentControlName: 'ExistingConcreteBlockGroup',
            },
            {
                ControlType: 'TextBox',
                UnitGroup: 100,
                Name: 'existing-concreteheight-input-textbox',
                Size: 50,
                TitleDisplayKey: isACICSA(design) ? 'Agito.Hilti.C2C.Overlay.Geometry.ConcreteHeigh.HNA' : 'Agito.Hilti.C2C.Overlay.Geometry.ConcreteHeigh',
                UIPropertyId: 10048,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: '',
                ParentControlName: isACICSA(design) ? null as unknown as string : 'ExistingConcreteBlockGroup',
                DisplayKey: 'Agito.Hilti.C2C.Overlay.Geometry.ConcreteHeigh'
            },
            {
                ControlType: 'TextBox',
                UnitGroup: 100,
                Name: 'existing-flangethickness',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.Overlay.Geometry.FlangeThickness',
                UIPropertyId: 10819,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: '',
                DisplayKey: 'Agito.Hilti.C2C.Overlay.Geometry.FlangeThickness',
                ParentControlName: 'ExistingConcreteBlockGroup'
            },
            {
                ControlType: 'TextBox',
                UnitGroup: 100,
                Name: 'existing-concretecoverbottom-input-textbox',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.Overlay.Geometry.ConcreteCoverBottom',
                UIPropertyId: 10049,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: '',
                ParentControlName: isACICSA(design) ? null as unknown as string : 'ExistingConcreteBlockGroup',
                DisplayKey: 'Agito.Hilti.C2C.Overlay.Geometry.ConcreteCoverBottom'
            },
            {
                ControlType: 'TextBox',
                UnitGroup: 100,
                Name: 'existing-end-cover-c1',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabNewConcrete.ExistingConcrete.EndCoverC1',
                UIPropertyId: 10488,
                TooltipDisplayKey: 'Agito.Hilti.C2C.Navigation.TabNewConcrete.ExistingConcrete.EndCoverC1.Tooltip',
                TooltipTitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabNewConcrete.ExistingConcrete.EndCoverC1',
                ParentControlName: isACICSA(design) ? null as unknown as string : 'ExistingConcreteBlockGroup',
                DisplayKey: 'Agito.Hilti.C2C.Navigation.TabNewConcrete.ExistingConcrete.EndCoverC1'
            },
            {
                ControlType: 'Group',
                Name: 'NewConcreteBlockGroup',
                TitleDisplayKey: 'Agito.Hilti.C2C.Overlay.Geometry.NewConcreteBlock',
                DisplayKey: 'Agito.Hilti.C2C.Overlay.Geometry.NewConcreteBlock',
                MultilineGroup: true
            },
            {
                ControlType: 'TextBox',
                UnitGroup: 100,
                Name: 'new-concreteheight-input-textbox',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.Overlay.Geometry.ConcreteHeigh',
                UIPropertyId: 10054,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: '',
                ParentControlName: 'NewConcreteBlockGroup',
            },
            {
                ControlType: 'TextBox',
                UnitGroup: 100,
                Name: 'new-concretecovertop-input-textbox',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.Overlay.Geometry.ConcreteCoverTop',
                UIPropertyId: 10050,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: '',
                ParentControlName: 'NewConcreteBlockGroup',
            }]
    };
}

function createC2CMenu_OverlayConcreteBlockDefinition_Zones(): RegionC2C {
    return {
        DisplayKey: 'Agito.Hilti.C2C.Navigation.TabBaseMaterial.RegionZones',
        Id: 22,
        Name: 'zones-section',
        Controls: [
            {
                ControlType: 'Dropdown',
                Name: 'numberofzones-dropdown',
                TitleDisplayKey: 'Agito.Hilti.C2C.Overlay.Zones.NumberOfZones',
                UIPropertyId: 10082,
                CodelistName: 'ZonesNumber',
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            },
            {
                ControlType: 'Checkbox',
                DisplayKey: 'Agito.Hilti.C2C.Overlay.Zones.Symmetric',
                Name: 'symmetric',
                UIPropertyId: 10083,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            },
            {
                ControlType: 'Checkbox',
                DisplayKey: 'Agito.Hilti.C2C.Overlay.Zones.ConsideringEdgeArea',
                Name: 'consideredgearea',
                UIPropertyId: 10084,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            },
            {
                ControlType: 'Group',
                Name: 'zonewidth',
                MultilineGroup: true,
                CollapsableGroup: true,
                ButtonType: ButtonType.Primary,
            },
            {
                ControlType: 'TextBox',
                Size: 85,
                Name: 'z1width',
                TitleDisplayKey: 'Agito.Hilti.C2C.Overlay.Zones.Z1Width',
                UIPropertyId: 10085,
                UnitGroup: UnitGroup.Length,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: '',
                ParentControlName: 'zonewidth',
            },
            {
                ControlType: 'ToggleImageButton',
                DisplayKey: '',
                Name: 'isz1widthlocked',
                UIPropertyId: 10089,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: '',
                ParentControlName: 'zonewidth',
                ButtonSize: ButtonSize.Normal,
                Image: 'lock-small',
                AlternateImage: 'lock-small-selected',
                Position: 'right',
                Size: 10
            },
            {
                ControlType: 'TextBox',
                Name: 'z2width',
                Size: 85,
                TitleDisplayKey: 'Agito.Hilti.C2C.Overlay.Zones.Z2Width',
                UIPropertyId: 10086,
                UnitGroup: UnitGroup.Length,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: '',
                ParentControlName: 'zonewidth'
            },
            {
                ControlType: 'ToggleImageButton',
                DisplayKey: '',
                Name: 'isz2widthlocked',
                UIPropertyId: 10090,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: '',
                ParentControlName: 'zonewidth',
                Image: 'lock-small',
                AlternateImage: 'lock-small-selected',
                Position: 'right',
                Size: 10
            },
            {
                ControlType: 'TextBox',
                Name: 'z3width',
                Size: 85,
                TitleDisplayKey: 'Agito.Hilti.C2C.Overlay.Zones.Z3Width',
                UIPropertyId: 10087,
                UnitGroup: UnitGroup.Length,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: '',
                ParentControlName: 'zonewidth'
            },
            {
                ControlType: 'ToggleImageButton',
                DisplayKey: '',
                Name: 'isz3widthlocked',
                UIPropertyId: 10091,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: '',
                ParentControlName: 'zonewidth',
                Image: 'lock-small',
                AlternateImage: 'lock-small-selected',
                Position: 'right',
                Size: 10

            },
            {
                ControlType: 'TextBox',
                Name: 'z4width',
                TitleDisplayKey: 'Agito.Hilti.C2C.Overlay.Zones.Z4Width',
                UIPropertyId: 10088,
                UnitGroup: UnitGroup.Length,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: '',
                Size: 85,
                ParentControlName: 'zonewidth'
            },
            {
                ControlType: 'ToggleImageButton',
                DisplayKey: '',
                Name: 'isz4widthlocked',
                UIPropertyId: 10092,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: '',
                Image: 'lock-small',
                AlternateImage: 'lock-small-selected',
                ParentControlName: 'zonewidth',
                Position: 'right',
                Size: 10
            }]
    };
}

function createC2CMenu_OverlayConcreteBlockDefinition_Reinforcement(design: DesignC2C): RegionC2C {
    return {
        DisplayKey: 'Agito.Hilti.Profis3.Navigation.TabBaseMaterial.RegionReinforcement',
        Id: 21,
        Name: 'reinforcement-section',
        Controls: [
            {
                ControlType: 'Label',
                UnitGroup: UnitGroup.None,
                Name: 'concrete-reinforcement-title',
                TitleDisplayKey: 'Agito.Hilti.C2C.Overlay.Reinforcement.ConcreteReinforcementTitle',
                UIPropertyId: 10506,
            },
            {
                CodelistName: 'ConcreteReinforcement',
                ControlType: 'Dropdown',
                Name: 'concretedropdown-dropdown',
                TitleDisplayKey: 'Agito.Hilti.C2C.Overlay.Reinforcement.ConcreteReinforcement',
                UIPropertyId: 10051,
                TooltipDisplayKey: 'Agito.Hilti.C2C.Overlay.Reinforcement.ConcreteReinforcement.Tooltip',
                TooltipTitleDisplayKey: 'Agito.Hilti.C2C.Overlay.Reinforcement.ConcreteReinforcement.Tooltip.Title'
            },
            {
                ControlType: 'Checkbox',
                DisplayKey: 'Agito.Hilti.C2C.Overlay.Reinforcement.ReinforcementControlCracking',
                Name: 'reinforcement-to-control',
                UIPropertyId: 10053,
                TooltipDisplayKey: 'Agito.Hilti.C2C.Overlay.Reinforcement.ReinforcementControlCracking.Tooltip',
                TooltipTitleDisplayKey: 'Agito.Hilti.C2C.Overlay.Reinforcement.ReinforcementControlCracking.Tooltip.Title'
            },
            {
                ControlType: 'Label',
                UnitGroup: UnitGroup.None,
                Name: 'concrete-reinforcement-new-title',
                TitleDisplayKey: 'Agito.Hilti.C2C.Overlay.Reinforcement.ConcreteReinforcementNewTitle',
                UIPropertyId: 10507,
            },
            {
                CodelistName: 'ConcreteReinforcement',
                ControlType: 'Dropdown',
                Name: 'new-concretedropdown-dropdown',
                TitleDisplayKey: 'Agito.Hilti.C2C.Overlay.Reinforcement.ConcreteReinforcementNew',
                UIPropertyId: 10504,
                TooltipDisplayKey: 'Agito.Hilti.C2C.Overlay.Reinforcement.ConcreteReinforcementNew.Tooltip',
                TooltipTitleDisplayKey: 'Agito.Hilti.C2C.Overlay.Reinforcement.ConcreteReinforcementNew.Tooltip.Title'
            },
            {
                ControlType: 'Checkbox',
                DisplayKey: 'Agito.Hilti.C2C.Overlay.Reinforcement.ReinforcementControlCrackingNew',
                Name: 'new-reinforcement-to-control',
                UIPropertyId: 10505,
                TooltipDisplayKey: 'Agito.Hilti.C2C.Overlay.Reinforcement.ReinforcementControlCrackingNew.Tooltip',
                TooltipTitleDisplayKey: 'Agito.Hilti.C2C.Overlay.Reinforcement.ReinforcementControlCrackingNew.Tooltip.Title'
            },
            {
                ControlType: 'Checkbox',
                DisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.DetailedDefinition',
                Name: 'detailed-definition',
                UIPropertyId: 10493,
                TooltipType: getPopupTooltipConditional(isACICSA(design)),
                Command: getCommandConditional('OpenDetailedDefinitionPopup', isACICSA(design))
            },
            {
                ControlType: 'TextBox',
                Name: 'total-cross-sectional-area',
                TitleDisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.TotalCrossSectionalArea',
                UIPropertyId: 10494,
                UnitGroup: UnitGroup.Area,
            },
            {
                ControlType: 'TextBox',
                Name: 'yield-strength-overlay',
                TitleDisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.YieldStrengthTransverseReinforcement',
                UIPropertyId: 10281,
                UnitGroup: UnitGroup.Stress
            },
            {
                ControlType: 'TextBox',
                Name: 'spacing',
                TitleDisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.Spacing',
                UIPropertyId: 10495,
                UnitGroup: UnitGroup.Length,
            },
            {
                ControlType: 'TextBox',
                Name: 'number-of-bars',
                UIPropertyId: 10496,
                TitleDisplayKey: 'Agito.Hilti.C2C.TransverseReinforcement.NumberOfBars'
            },
            {
                ControlType: 'TextBox',
                Name: 'ktr',
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.Ktr',
                UIPropertyId: 10268,
                UnitGroup: UnitGroup.Length,
            },
            {
                ControlType: 'Checkbox',
                Name: 'splicetoexistingreinforcement',
                DisplayKey: 'Agito.Hilti.C2C.Reinforcement.SpliceToExistingReinforcement',
                UIPropertyId: 10280,
            },
            {
                ControlType: 'Dropdown',
                CodelistName: 'ExistingReinforcementDiameter',
                Size: 50,
                Name: 'diameteroftheexistingreinforcement-dropdown',
                TitleDisplayKey: 'Agito.Hilti.C2C.Reinforcement.Diameter',
                UIPropertyId: 10287
            },
            {
                ControlType: 'RadioButtonGroup',
                Name: 'splicesclasses-radio-buttons',
                UIPropertyId: 10288,
                Size: 50,
                CodelistName: 'SpliceClass',
                DisplayKey: 'splice-classes-radio-buttons',
                TitleDisplayKey: 'Agito.Hilti.C2C.Overlay.Reinforcement.SpliceClasses'
            },
            {
                ControlType: 'RadioButtonGroup',
                Name: 'reinforcedInterface-radio-buttons',
                UIPropertyId: 10368,
                CodelistName: 'ReinforcedInterface',
                TitleDisplayKey: 'Agito.Hilti.C2C.Overlay.Reinforcement.ReinforcedInterface'
            },
            {
                ControlType: 'Checkbox',
                DisplayKey: 'Agito.Hilti.C2C.Overlay.Reinforcement.ConstructiveReinforcement',
                Name: 'constructiveReinforcement',
                UIPropertyId: 10369
            },
        ]
    };
}

function createC2CMenu_OverlayConcreteBlockDefinition_ModelRotation(): RegionC2C {
    return {
        DisplayKey: 'Agito.Hilti.C2C.Navigation.TabNewConcrete.ModelRotation.Title',
        Name: 'ACI-CSA-3Drotation-section',
        Id: 25,
        Controls: [
            {
                ControlType: 'TextBox',
                UnitGroup: 800,
                Name: 'ACI-CSA-3Drotation',
                Size: 50,
                DisplayKey: 'Agito.Hilti.C2C.Navigation.TabNewConcrete.ModelRotation.Angle',
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabNewConcrete.ModelRotation.Angle',
                UIPropertyId: 10485
            },
            {
                ControlType: 'Rotate',
                RotateLeftUIPropertyId: 10487,
                RotateRightUIPropertyId: 10486,
                Name: 'ACI-CSA-3Drotation-buttons',
                Size: 50,
                DisplayKey: 'Agito.Hilti.C2C.Navigation.TabNewConcrete.ModelRotation.Rotation',
                TitleDisplayKey: 'Agito.Hilti.C2C.Navigation.TabNewConcrete.ModelRotation.Rotation',
                UIPropertyId: 10486
            }
        ]
    };
}

export function createC2CMenu_OverlayInstallationCondition_BaseMaterial(design: DesignC2C): RegionC2C {
    return {
        DisplayKey: 'Agito.Hilti.C2C.Navigation.TabBaseMaterial.RegionBaseMaterial',
        Id: 17,
        Name: 'basematerial-section',
        Controls: [
            {
                ControlType: 'Checkbox',
                DisplayKey: 'Agito.Hilti.C2C.Overlay.BaseMaterial.CrackedConcrete',
                Name: 'crackedconcrete',
                UIPropertyId: 10073,
                TooltipDisplayKey: isACICSA(design) ? 'Agito.Hilti.C2C.BaseMaterial.CrackedConcrete.Tooltip.Text' : ''
            },
            {
                CodelistName: 'BaseMaterialStructure',
                ControlType: 'Dropdown',
                Name: 'existing-basematerial-dropdown',
                UIPropertyId: 10074,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            },
            {
                ControlType: 'TextBox',
                Name: 'existing-fccyl-input',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.Overlay.BaseMaterial.ExistingStructure.FcCyl',
                UIPropertyId: 10078,
                UnitGroup: UnitGroup.Stress,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            },
            {
                ControlType: 'TextBox',
                Name: 'existing-fccube-input',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.Overlay.BaseMaterial.ExistingStructure.FcCube',
                UIPropertyId: 10079,
                UnitGroup: UnitGroup.Stress,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            },
            {
                ControlType: 'Group',
                Name: 'new-basematerial-structure-group',
                DisplayKey: 'Agito.Hilti.C2C.Overlay.BaseMaterial.NewStructure',
                TitleDisplayKey: 'Agito.Hilti.C2C.Overlay.BaseMaterial.NewStructure',
                UIPropertyId: 10817,
                MultilineGroup: false
            },
            {
                ControlType: 'Group',
                Name: 'new-basematerial-structure-concrete-type',
                DisplayKey: 'Agito.Hilti.C2C.Overlay.BaseMaterial.ConcreteType',
                TitleDisplayKey: 'Agito.Hilti.C2C.Overlay.BaseMaterial.ConcreteType',
                UIPropertyId: 10817,
                MultilineGroup: false,
                ParentControlName: 'new-basematerial-structure-group'
            },
            {
                ControlType: 'Label',
                Name: 'new-basematerial-structure-title',
                UIPropertyId: 10817,
                ParentControlName: 'new-basematerial-structure-concrete-type'
            },
            {
                CodelistName: 'ConcreteType',
                ControlType: 'Dropdown',
                Name: 'concrete-type-dropdown',
                UIPropertyId: 10825,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            },
            {
                CodelistName: 'BaseMaterialStructure',
                ControlType: 'Dropdown',
                Name: 'new-basematerial-lightweight-dropdown',
                UIPropertyId: 10826,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            },
            {
                CodelistName: 'BaseMaterialStructure',
                ControlType: 'Dropdown',
                Name: 'new-basematerial-dropdown',
                TitleDisplayKey: isThinOverlay(design) ? undefined : 'Agito.Hilti.C2C.Overlay.BaseMaterial.NewStructure',
                UIPropertyId: 10075,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            },
            {
                ControlType: 'TextBox',
                Name: 'new-fccyl-input',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.Overlay.BaseMaterial.NewStructure.FcCyl',
                UIPropertyId: 10076,
                UnitGroup: UnitGroup.Stress,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            },
            {
                ControlType: 'TextBox',
                Name: 'new-fccube-input',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.Overlay.BaseMaterial.NewStructure.FcCube',
                UIPropertyId: 10077,
                UnitGroup: UnitGroup.Stress,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            },
            {
                ControlType: 'Checkbox',
                Name: 'basematerial-lightweightconcrete',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.BaseMaterial.LightweightConcrete.Title',
                DisplayKey: 'Agito.Hilti.C2C.BaseMaterial.LightweightConcrete',
                UIPropertyId: 10266,
                TooltipDisplayKey: '',
                TooltipTitleDisplayKey: ''
            },
            {
                CodelistName: 'ConcreteDensity',
                ControlType: 'Dropdown',
                Name: 'concretedensity-dropdown',
                TitleDisplayKey: 'Agito.Hilti.C2C.Overlay.BaseMaterial.ConcreteDensity',
                UIPropertyId: 10272,
                Size: 50,
                TooltipType: TooltipType.Popup,
                Command: 'OpenConcreteDensityPopup'
            },
            {
                ControlType: 'TextBox',
                Name: 'basematerial-lambda',
                Size: 50,
                TitleDisplayKey: 'Agito.Hilti.C2C.BaseMaterial.TensileStrength',
                UIPropertyId: 10267,
                TooltipType: TooltipType.Popup,
                Command: 'OpenTensileStrengthPopup'
            },
        ]
    };
}

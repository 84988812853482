import { NgZone } from '@angular/core';
import { ConnectionType, InvokeOptions, IPendingRequest, ISignalRError, SignalRConnectionOptions } from '@profis-engineering/pe-ui-common/services/signalr.common';
import { environment } from '../../environments/environmentC2C';
import { ApiService } from './api.service';
import { AuthenticationService } from './authentication.service';
import { GuidService } from './guid.service';
import { LocalizationService } from './localization.service';
import { LoggerService } from './logger.service';
import { ModalService } from './modal.service';
import { UserService } from './user.service';
import * as signalRCore from '@microsoft/signalr';
import { CalculateDesignRequestC2C, CalculationResultEntityC2C } from '../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Entities';
import { hubConnection, signalR, SignalR } from '../../scripts/agito.signalr.cjs';
import { LogType } from '@profis-engineering/pe-ui-common/services/logger.common';
import { Deferred } from '@profis-engineering/pe-ui-common/helpers/deferred';
import { HttpRequest, HttpStatusCode } from '@angular/common/http';
import { SignalRConnectionC2C, SignalRServiceC2CBase } from '../../shared/services/signalr.service.base';
import { SignalRExceptionHandler } from './helpers/signalr-exception-handler';

declare const Zone: any;

export function buildSignalRHubConnection(baseUrl: string, accessTokenFactory: () => string, onDisconnected: (coreConnection: signalRCore.HubConnection) => void) {
    const options = {
        accessTokenFactory,
        skipNegotiation: true,
        transport: signalRCore.HttpTransportType.WebSockets
    } as signalRCore.IHttpConnectionOptions;

    const coreConnection = new signalRCore.HubConnectionBuilder()
        .withUrl(baseUrl, options)
        .configureLogging(signalRCore.LogLevel.Warning)
        .build();

    coreConnection.onclose(() => onDisconnected(coreConnection));
    return coreConnection;
}

export class SignalRConnection extends SignalRConnectionC2C {
    private pendingRequests: Record<string, IPendingRequest>;

    private connection?: SignalR.Hub.Connection;
    private hubProxy?: SignalR.Hub.Proxy;

    private coreConnection?: signalR.HubConnection;

    // Unknown => not known yet if websockets (SignalR Core) or http long polling (SignalR legacy).
    private connectionType: ConnectionType;
    private idleTimeoutId?: number;

    private connectingPromise?: Promise<void>;

    private lastRequestId: string;
    private lastRequestData!: string | object;
    private lastResponseData!: string | object;

    constructor(
        private readonly modalService: ModalService,
        private readonly loggerService: LoggerService,
        private readonly userService: UserService,
        private readonly guidService: GuidService,
        private readonly localizationService: LocalizationService,
        private readonly apiService: ApiService,
        private readonly authenticationService: AuthenticationService,
        private readonly ngZone: NgZone,
        private connectionOptions: SignalRConnectionOptions,
    ) {
        super();

        NgZone.assertInAngularZone();

        this.onProgress = this.onProgress.bind(this);
        this.onDone = this.onDone.bind(this);
        this.onFail = this.onFail.bind(this);

        this.pendingRequests = {};

        this.connectionType = connectionOptions.useHttpLongPolling ? ConnectionType.Http : ConnectionType.Unknown;

        // when auth token changes
        this.userService.authenticated.subscribe(() => {
            this.ngZone.run(() => this.setHubConnections());
        });

        /*
        * Set initial lastRequestId, usable when:
        * - the calculation was not invoked
        * - the TransactionId is not accessible
        */
        this.lastRequestId = this.guidService.new();
    }

    public get connectionUrl() {
        if (this.connectionType != ConnectionType.Http && this.coreConnection != null) {
            return this.coreConnection.baseUrl;
        }
        else {
            return this.connection?.url ?? '';
        }
    }

    public get requestData() {
        return this.lastRequestData;
    }

    public get responseData() {
        return this.lastResponseData;
    }

    public get requestId() {
        return this.lastRequestId;
    }

    public setHubConnectionsC2C() {
        this.setHubConnections(this.connectionOptions);
    }

    public setHubConnections(connectionOptions?: SignalRConnectionOptions) {
        NgZone.assertInAngularZone();

        if (connectionOptions != null) {
            this.connectionOptions = connectionOptions;
        }

        if (this.connectionOptions == null) {
            // no connection set
            return;
        }

        // no need for websockets (SignalR Core) if Http
        // if Unknown we don't yet know if it's websockets (SignalR Core) or http long polling (SignalR legacy) so we run both hub connection functions
        if (this.connectionType != ConnectionType.Http) {
            this.setHubConnectionInternal(this.connectionOptions.signalRCoreServerUrl, this.connectionOptions.signalRCoreServerHub);
        }

        this.setHttpHubConnectionInternal(this.connectionOptions.legacySignalRServerUrl ?? '', this.connectionOptions.legacySignalRCoreServerHub);
    }

    public async calculateDesignC2C(calculateDesignRequest: CalculateDesignRequestC2C, options: InvokeOptions) {
        this.lastRequestData = calculateDesignRequest;

        const resp = await this.invoke<CalculationResultEntityC2C>('CalculateDesign', options, calculateDesignRequest);
        return this.modifyCalculationResultsC2C(resp);
    }

    // this is needed in case of using legacy fallback.
    private modifyCalculationResultsC2C(result: unknown) {
        // calculation legacy hub sends json string that needs to be parsed into object in the right way.
        if (typeof (result) == 'string' || (result instanceof String)) {
            return JSON.parse(result as string) as CalculationResultEntityC2C;
        }

        return result as CalculationResultEntityC2C;
    }

    private setHttpHubConnectionInternal(url: string, hub: string) {
        NgZone.assertInAngularZone();
        const zone = Zone.current;

        const headers = this.userService.getHeaders(url, false);

        if (headers?.['HC-TransactionId']) {
            this.lastRequestId = headers['HC-TransactionId'];
        }

        signalR.ajaxDefaults.headers = headers;

        // if we are not connected to the correct SignalR instance (C2C for example) disconnect first but only if no requests are running
        // no need to check the token since it can be changed on existing connection (line above)
        // on connect we also check if we need to reconnect by calling setHubConnections
        if (this.connection != null && this.connection.url != url) {
            // stop will also call onDisconnected but it will be async and ignored
            // since we might already have a new connection with new pending requests that must not be canceled
            this.onDisconnected(this.connection);

            this.connection.stop();
            this.connection = undefined;
        }

        // connect if we don't have a connection
        if (this.connection == null) {
            const qs = environment.c2cDemoFeatures ? `${encodeURIComponent('c2cdemo')}=${encodeURIComponent('true')}` : '';
            // legacy SignalR
            this.connection = hubConnection(url, {
                useDefaultPath: false,
                qs: qs
            });
            this.hubProxy = this.connection.createHubProxy(hub);

            this.hubProxy.on('Progress', (requestId: string, progress: unknown) => {
                NgZone.assertNotInAngularZone();
                zone.run(() => this.onProgress(requestId, progress));
            });
            this.hubProxy.on('Done', (requestId: string, result: unknown) => {
                NgZone.assertNotInAngularZone();
                zone.run(() => this.onDone(requestId, result));
            });
            this.hubProxy.on('Fail', (requestId: string, response: string) => {
                NgZone.assertNotInAngularZone();
                zone.run(() => this.onFail(requestId, response));
            });

            const connection = this.connection;
            this.connection.disconnected(() => {
                if (NgZone.isInAngularZone()){
                    this.onDisconnected(connection);
                }
                else{
                    zone.run(() => this.onDisconnected(connection));
                }
            });
        }
    }

    private addQueryToUrl(url: string): string {
        const hasQueries = url.includes('?');
        const queryAppendStartChar = hasQueries ? '&' : '?';

        if (environment.c2cDemoFeatures) {
            url += queryAppendStartChar + encodeURIComponent('c2cdemo') + '=' + encodeURIComponent('true');
        }

        return url;
    }

    private setHubConnectionInternal(url: string, hub: string) {
        NgZone.assertInAngularZone();

        // convert headers to query string
        const headers = this.userService.getHeaders(url, false);
        let headerQuery = '';

        if (this.connectionOptions?.accessToken == 'local') {
            for (const headerName in headers) {
                headerQuery += '&' + encodeURIComponent(headerName) + '=' + encodeURIComponent(headers[headerName]);
            }
        }

        if (headerQuery.startsWith('&')) {
            headerQuery = '?' + headerQuery.substring(1);
        }

        const baseUrl = this.addQueryToUrl(url + hub + headerQuery);
        const onDisconnected = this.onDisconnected.bind(this);

        if (headers?.['HC-TransactionId']) {
            this.lastRequestId = headers['HC-TransactionId'];
        }

        // if we are not connected to the correct SignalR instance (C2C for example or token changes) disconnect first but only if no requests are running
        // on connect we also check if we need to reconnect by calling setHubConnections
        if (this.coreConnection &&
            this.coreConnection.baseUrl != baseUrl &&
            Object.keys(this.pendingRequests).length == 0) {

            // stop will also call onDisconnected but it will be async and ignored
            // since we might already have a new connection with new pending requests that must not be canceled
            this.coreConnection.stop();

            this.onDisconnected(this.coreConnection);
            this.coreConnection = undefined;
        }

        // create connection if we don't have it
        if (!this.coreConnection) {
            // SignalR Core
            if (this.connectionOptions.accessToken == 'header' || (this.connectionOptions.accessToken == 'local')) {
                this.coreConnection = buildSignalRHubConnection(baseUrl, () => this.userService.authentication.accessToken, onDisconnected);
            }
            else if ((this.connectionOptions.accessToken == 'cookie')) {
                this.coreConnection = buildSignalRHubConnection(baseUrl, () => '', onDisconnected);
            }
            else {
                this.loggerService.log(
                    'SignalRConnection::setHubConnectionInternal connectionOptions.accessToken is Unknown',
                    LogType.error);
            }
        }
    }

    private async cancelCalculation(requestId: string): Promise<void> {
        NgZone.assertInAngularZone();

        const request = this.pendingRequests[requestId];
        const methodName = 'CancelCalculation';

        if (request != null) {
            delete this.pendingRequests[requestId];
            request.deferred.reject();

            try {
                await this.connect();
                await this.invokeInternal<void>(methodName, this.guidService.new(), requestId);
            }
            catch (error) {
                // catchException will throw the error or block for redirect
                await this.catchException(error as string | ISignalRError, requestId);
            }
        }
    }

    private invoke<T>(methodName: string, options: InvokeOptions, ...args: any[]): Promise<T> {
        NgZone.assertInAngularZone();

        options = options ?? {};

        const deferred = new Deferred<T>();
        let isCanceled = false;

        // request for invoke (with optional connect)
        const requestId = this.guidService.new();
        this.lastRequestId = requestId;

        this.connect()
            .then(() => this.invokeInternal<T>(methodName, requestId, ...args))
            .then(result => {
                this.lastResponseData = result as unknown as object;
                if (!isCanceled) {
                    // LEGACY - if we get back null the request has transitioned into async mode and is not done yet so we ignore the result
                    if (this.connectionType == ConnectionType.Websocket || result != null) {
                        this.onDone(requestId, result);
                    }
                }
            })
            .catch(async (reason: ISignalRError) => {
                this.lastResponseData = reason;
                if (!isCanceled) {
                    if (this.connectionType == ConnectionType.Websocket) {
                        const request = this.pendingRequests[requestId];

                        if (request != null) {
                            delete this.pendingRequests[requestId];
                            clearTimeout(request.timeoutId);
                        }
                    }

                    await this.catchException(reason, requestId)
                        .catch(error => deferred.reject(error));
                }
            });

        if (options.cancel != null) {
            options.cancel.finally(() => {
                isCanceled = true;

                if (pendingRequest != null) {
                    pendingRequest.isCanceled = true;
                }

                return this.cancelCalculation(requestId);
            });
        }

        // save pending request
        const pendingRequest: IPendingRequest = this.pendingRequests[requestId] = {
            deferred,
            onProgress: options.onProgress,
            timeoutId: this.createTimeout(methodName, requestId, deferred, options.timeout),
            cancel: options.cancel,
            isCanceled
        };

        return deferred.promise;
    }

    private async catchException(reason: ISignalRError | string, requestId?: string): Promise<void> {
        NgZone.assertInAngularZone();

        const exHandler = new SignalRExceptionHandler(this.modalService, this.localizationService, this.authenticationService, this.loggerService);
        await exHandler.handle(reason, this.connectionUrl, this.connectionType, requestId);
    }


    private invokeInternal<T>(methodName: string, requestId: string, ...args: any[]): Promise<T> {
        NgZone.assertInAngularZone();
        const zone = Zone.current;

        return new Promise<T>((resolve, reject) => {
            if (this.connectionType == ConnectionType.Websocket) {
                this.invokeInternalWebSocket<T>(methodName, requestId, args, zone, resolve, reject);
            }
            else if (this.connectionType == ConnectionType.Http) {
                this.invokeInternalHttp<T>(methodName, requestId, args, zone, resolve, reject);
            }
            else {
                throw new Error('connection type not set');
            }
        });
    }

    private invokeInternalWebSocket<T>(methodName: string, requestId: string, args: any[], zone: any, resolve: (value: T | PromiseLike<T>) => void, reject: (reason?: any) => void) {
        this.ngZone.runOutsideAngular(() => this.coreConnection?.stream(methodName, ...[requestId, ...args])
            .subscribe({
                next: value => {
                    this.nextInternal<T>(zone, value, requestId, resolve);
                },
                complete: () => {
                    // already handled in the next part
                },
                error: error => {
                    NgZone.assertNotInAngularZone();
                    zone.run(() => {
                        // if we get an Error object just return the message
                        if (error != null && error instanceof Error) {
                            error = error.message;
                        }

                        this.startIdleTimeout();
                        reject(new Error(error));
                    });
                }
            })
        );
    }

    private invokeInternalHttp<T>(methodName: string, requestId: string, args: any[], zone: any, resolve: (value: T | PromiseLike<T>) => void, reject: (reason?: any) => void) {
        this.ngZone.runOutsideAngular(() => this.hubProxy?.invoke(methodName, ...[requestId, ...args])
            .done((arg: any) => {
                NgZone.assertNotInAngularZone();
                zone.run(() => resolve(arg));
            })
            .fail((...args: any[]) => {
                NgZone.assertNotInAngularZone();
                zone.run(() => reject(...args));
            })
        );
    }

    private nextInternal<T>(zone: any, value: any, requestId: string, resolve: (value: T | PromiseLike<T>) => void) {
        NgZone.assertNotInAngularZone();
        zone.run(() => {
            // we have progress or result
            if (value.progress != null || value.Progress != null) {
                this.onProgress(requestId, value.progress || value.Progress);
            }
            else {
                this.startIdleTimeout();
                resolve(value.result || value.Result);
            }
        });
    }

    private startIdleTimeout() {
        NgZone.assertInAngularZone();

        if (this.idleTimeoutId) {
            clearTimeout(this.idleTimeoutId);
        }

        this.idleTimeoutId = setTimeout(() => {
            this.idleTimeoutId = undefined;

            // there might be new pending requests when timeout is triggered
            // ignore it since the pending request will set the timeout
            if (Object.keys(this.pendingRequests).length == 0) {
                // disconnect
                if (this.coreConnection) {
                    this.coreConnection.stop();
                    this.onDisconnected(this.coreConnection);
                    this.coreConnection = undefined;
                }

                // set up connection for the next connect that might come later
                this.setHubConnections();
            }
        }, SignalRConnection.idleTimeout);
    }

    private createTimeout(methodName: string, requestId: string, deferred: Deferred<unknown>, timeout: number | undefined = undefined): number {
        NgZone.assertInAngularZone();

        if (timeout == 0) {
            return setTimeout(() => { return; }, 0);
        }

        return setTimeout(() => {
            const request = this.pendingRequests[requestId];

            if (request != null) {
                delete this.pendingRequests[requestId];

                const message = `Operation ${methodName} has timed out.`;

                // Only show timeout error if request wasn't canceled.
                if (request.cancel == null || !request.isCanceled) {
                    this.modalService.openAlertSignalRError({
                        response: message,
                        correlationId: requestId,
                        endPointUrl: this.coreConnection?.baseUrl
                    });
                }

                deferred.reject(message);
            }
        }, timeout ?? this.connectionOptions.signalRTimeoutInMilliseconds);
    }

    private connect(): Promise<void> {
        NgZone.assertInAngularZone();

        this.setHubConnections();

        if (this.connection?.state == SignalR.ConnectionState.Connected) {
            return Promise.resolve();
        }

        if (this.connectingPromise) {
            return this.connectingPromise;
        }

        this.connectingPromise = (async () => {
            try {
                // ASP.NET Core SignalR
                if (this.connectionType != ConnectionType.Http) {
                    await this.signalRConnect();
                    this.connectionType = ConnectionType.Websocket;
                }
                else {
                    // go to catch and try legacy
                    throw new Error('websockets not supported');
                }
            }
            catch (error) {
                // ASP.NET SignalR (Legacy)
                await this.signalRConnectLegacy();

                this.connectionType = ConnectionType.Http;
            }
            finally {
                this.connectingPromise = undefined;
            }
        })();

        return this.connectingPromise;
    }

    private async signalRConnect(): Promise<void> {
        NgZone.assertInAngularZone();

        // return if we are already connected
        if (this.coreConnection?.state == signalRCore.HubConnectionState.Connected) {
            return;
        }

        if (this.connectionOptions.accessToken == 'header' || this.connectionOptions.accessToken == 'local') {
            await this.connectionStart();
        }
        else if (this.connectionOptions.accessToken == 'cookie') {
            await this.apiService.request(new HttpRequest('GET', this.connectionOptions.signalRCoreInitSessionUrl ?? '', { withCredentials: true }));
            await this.connectionStart();
        }
        else {
            throw new Error('SignalRConnection::connect this.connectionOptions.accessToken is Unknown');
        }
    }

    private async connectionStart(): Promise<void> {
        NgZone.assertInAngularZone();

        try {
            await this.ngZone.runOutsideAngular(async () => await this.coreConnection?.start());
            this.loggerService.log('ASP.NET Core SignalR connected. Protocol: WebSockets', LogType.info);
        }
        catch (error) {
            this.loggerService.log('ASP.NET Core SignalR connection failed.', LogType.error, error);

            throw error;
        }
    }

    private signalRConnectLegacy(): Promise<void> {
        NgZone.assertInAngularZone();
        const zone = Zone.current;

        return new Promise<void>((resolve, reject) => {
            this.signalRConnectLegacyInternal(zone, resolve, reject);
        });
    }

    private signalRConnectLegacyInternal(zone: any, resolve: (value: void | PromiseLike<void>) => void, reject: (reason?: any) => void) {
        this.ngZone.runOutsideAngular(() => this.connection?.start({
            transport: 'longPolling',
            withCredentials: false
        })
            .done(value => {
                NgZone.assertNotInAngularZone();
                zone.run(() => {
                    this.loggerService.log('ASP.NET SignalR Legacy connected. Protocol: ' + value.transport.name, LogType.warn);

                    resolve();
                });
            })
            .fail(reason => {
                NgZone.assertNotInAngularZone();
                zone.run(() => {
                    this.loggerService.log('ASP.NET SignalR Legacy connection failed.', LogType.error, reason);

                    const response: ISignalRError = {
                        message: reason?.context?.statusText && reason.context.statusText != '' ? reason.context.statusText : 'Unknown error',
                        data: {
                            StatusCode: reason?.context?.status && typeof reason.context.status == 'number' ? reason.context.status : 500
                        }
                    };
                    reject(new Error(response.message));
                });
            })
        );
    }

    private onProgress(requestId: string, progress: unknown) {
        NgZone.assertInAngularZone();

        const request = this.pendingRequests[requestId];
        request?.onProgress?.(progress);
    }

    private onDone(requestId: string, result: unknown) {
        NgZone.assertInAngularZone();

        const request = this.pendingRequests[requestId];

        if (request != null) {
            delete this.pendingRequests[requestId];
            clearTimeout(request.timeoutId);

            this.lastRequestId = requestId;

            request.deferred.resolve(result);
        }
    }

    private onFail(requestId: string, response: string) {
        NgZone.assertInAngularZone();

        const request = this.pendingRequests[requestId];

        if (request != null) {
            delete this.pendingRequests[requestId];
            clearTimeout(request.timeoutId);

            const data = JSON.parse(response);

            // In case request was canceled, we don't show error.
            if (request.cancel != null && request.isCanceled) {
                request.deferred.reject(data);
            }
            else {
                this.catchException({ message: data.ExceptionMessage, data }, requestId)
                    .catch(error => request.deferred.reject(error));
            }
        }
    }

    private onDisconnected(connection: SignalR.Hub.Connection | signalR.HubConnection) {
        NgZone.assertInAngularZone();

        const message = 'Client was disconnected.';

        // reject all requests for a connection
        // we might be closing an old connection while a new one is already opened
        // so we check the connection object and ignore if it's an old connection
        if (this.connection === connection || this.coreConnection === connection) {
            for (const requestId in this.pendingRequests) {
                const request = this.pendingRequests[requestId];

                delete this.pendingRequests[requestId];
                clearTimeout(request.timeoutId);

                request.deferred.reject(message);
            }

            // for Core SignalR also clear the connection object since it's in a broken state
            // first request will always fail with "WebSocket is not in the OPEN state" (bug in ASP.NET Core SignalR?)
            if (this.coreConnection === connection) {
                // we are already disconnected so we can just set it to null
                // on next request the connection will be reopened
                this.coreConnection = undefined;
            }
        }

        this.connectionType = ConnectionType.Unknown;
    }
}

export interface SignalRError {
    ExceptionMessage: string;
    ReasonPhrase: string;
    StackTrace: string;
    StatusCode: HttpStatusCode;
}

export interface ISignalRServiceConstructor {
    modalService: ModalService;
    loggerService: LoggerService;
    userService: UserService;
    guidService: GuidService;
    localizationService: LocalizationService;
    apiService: ApiService;
    authenticationService: AuthenticationService;
    ngZone: NgZone;
    options: SignalRConnectionOptions;
}

export class SignalRService extends SignalRServiceC2CBase {
    public common: SignalRConnection;

    constructor(
        ctor: ISignalRServiceConstructor
    ) {
        super();

        this.common = new SignalRConnection(
            ctor.modalService,
            ctor.loggerService,
            ctor.userService,
            ctor.guidService,
            ctor.localizationService,
            ctor.apiService,
            ctor.authenticationService,
            ctor.ngZone,
            ctor.options
        );
    }

    public setHubConnectionsC2C() {
        this.common.setHubConnectionsC2C();
    }
}
